import React, { Component, Fragment } from 'react';
import styled from 'styled-components/macro';
import {
  addUrlProps,
  UrlQueryParamTypes,
  multiReplaceInUrlQuery,
} from 'react-url-query';
import delve from 'dlv';
import { keys } from 'lodash';

import createApiUrl from 'helpers/createApiUrl';
import retrieveAnnotationIdsFromScores from 'helpers/retrieveAnnotationIdsFromScores';
import keysExistsInObject from 'helpers/keysExistsInObject';
import { GroupContext } from 'context/groupContext';

import ErrorPage from 'pages/ErrorPage';
import DataFetcher from 'components/DataFetcher/DataFetcher';
import Sidebar from 'components/Sidebar/Sidebar';
import Header from 'components/Header/Header';
import LineChartWrapper from 'components/LineChartWrapper/LineChartWrapper';
import CirclePackingWrapper from 'components/CirclePackingWrapper/CirclePackingWrapper';

import { REFERENCE_ID } from '../config';
import assignNewColorsToGroups from '../helpers/assignNewColorsToGroups';

const Container = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 64rem;
`;

const Main = styled('div')`
  flex: 1 1 100%;
  min-width: 0%;
  height: 100%;
`;

/**
 * Specify how the URL gets decoded here. This is an object that takes the prop
 * name as a key, and a query param specifier as the value. The query param
 * specifier can have a `type`, indicating how to decode the value from the
 * URL, and a `queryParam` field that indicates which key in the query
 * parameters should be read (this defaults to the prop name if not provided).
 * @see: https://peterbeshai.com/react-url-query/docs/api/
 */
const urlPropsQueryConfig = {
  activeGroups: {
    type: UrlQueryParamTypes.json,
    queryParam: 'groups',
  },
  from: {
    type: UrlQueryParamTypes.number,
  },
  to: {
    type: UrlQueryParamTypes.number,
  },
  selectedAnnotation: {
    type: UrlQueryParamTypes.string,
    queryParam: 'annotationId',
  },
  lineConfiguration: {
    type: UrlQueryParamTypes.array,
    queryParam: 'configuration',
  },
};

/**
 * This component renders the Home page, which is the default page of the
 * application. This page is loaded after an user is authenticated.
 */
class Home extends Component {
  state = {
    editModeEnabled: false,	
    clickedNode: null,
    count: 0,
    colorById: {},
  };
   

  static getDerivedStateFromProps = (props, state) => {
    return {
      colorById: assignNewColorsToGroups(state.colorById, props.activeGroups),
    };
  };

  /**
   * Submit new dates retrieved from the date-picker to the multiReplaceInUrlQuery
   * function form react-url-query.
   */
  submitDates = ({ startDateMoment, endDateMoment }) => {
    multiReplaceInUrlQuery({
      from: startDateMoment.unix(),
      to: endDateMoment.unix(),
    });
  };
  
  

  /**
   * An user with full access can toggle edit mode on or of in order
   * to create or delete annotations.
   */
  toggleEditMode = () => {
    const editModeEnabled = !this.state.editModeEnabled;

    if (this.state.editModeEnabled && this.state.clickedNode) {
      this.setState({
        editModeEnabled,
        clickedNode: null,
      });
    }
    this.setState({ editModeEnabled });
  };
    

  /**
   * Increase counter so we can fetch new data.
   */
  increaseCount = () => {
    this.setState({ count: this.state.count + 1 });
  };

  /**
   * Clear the clicked node after we create the annotation.
   */
  clearClickedNode = () => {
    this.setState({ clickedNode: null });
  };

  /**
   * Toggle smooth data on or off.
   */
  handleLineConfigurationToggle = configuration => {
    const lineConfiguration = this.props.lineConfiguration
      ? this.props.lineConfiguration
      : ['none'];

    let updatedLineConfiguration = [...lineConfiguration];

    if (lineConfiguration.includes(configuration)) {
      if (lineConfiguration.length !== 1)
        updatedLineConfiguration = lineConfiguration.filter(
          e => e !== configuration
        );
    } else updatedLineConfiguration.push(configuration);

    this.props.onChangeLineConfiguration(updatedLineConfiguration);
  };

  render() {
	  
    const {
      activeGroups,
      onChangeActiveGroups,
      from,
      to,
      selectedAnnotation,
      onChangeSelectedAnnotation,
      userHasFullAccess,
      lineConfiguration = ['none'],
    } = this.props;

    const { editModeEnabled, clickedNode, colorById, count } = this.state;

    return (
      <DataFetcher
        url={createApiUrl({ path: 'request_organization_metadata' })}
        render={({ data, success, loading, error }) => {
          /**
           * If there is no data yet, do not render.
           */
          if (!data) return null;

          /**
           * Collect errors and check if required keys do exist in data response
           * from the API.
           */
          if (!loading && error) {
            return <ErrorPage status={400} error={error} />;
          } else if (
            !keysExistsInObject(
              ['all_label', 'default_timespan', 'timezone'],
              data
            )
          ) {
            return (
              <ErrorPage error="The data retrieved from the API is missing required values." />
            );
          }

          /**
           * Retrieve date timestamps from query parameters, when those parameters
           * are not defined use the default from and to values retrieved from the
           * metadata API call.
           * The reason I use delve here is to prevent undefined of undefined errors.
           */
          const startDate = from
            ? from
            : delve(data, 'default_timespan.from', null);
          const endDate = to ? to : delve(data, 'default_timespan.to', null);
		  
		  const connections = delve(data, 'connections', null);		  

          /**
           * In order to create a reference line in the line chart we need to know
           * what the name of the reference line is. We will retrieve this information
           * from the "request_organization_metadata" API call.
           *
           * The API response contains for example: `"all_label":{"All":["All"]}` as key
           * and value. The value of "all_label" is then used to create an API call in
           * order to retrieve the reference line. The use id for the reference line is
           * always "All", which is agreed with KeenCorp.
           */
          const referenceGroup = data.all_label;
          const requestGroups = {
            ...referenceGroup,
            ...activeGroups,
          };

          /**
           * Create a list of active group ids and default to an empty list. This list
           * of ids is required in order to show line series and circles in both of the
           * two visualizations.
           * Note: Ideally we want to use the same ids used in the requests to retrieve
           * scores data, unfortunately the reference line is always returned as the "All"
           * key. Therefore we need to use the key for "All" and the value for the rest.
           */
          const activeGroupIds = requestGroups ? keys(requestGroups) : [];

          /**
           * Build API request to retrieve scores and trend indicator data.
           */
          const apiRequest = {
            from: startDate,
            to: endDate,
            groups: requestGroups,
          };

          return (
            <Container>
              <GroupContext.Provider
                value={{
                  activeGroups: requestGroups,
                  activeGroupIds,
                  startDate,
                  endDate,
                  referenceId: REFERENCE_ID,
                  colorById,
                  userHasFullAccess,
                }}
              >
                <DataFetcher
                  url={createApiUrl({ path: 'request_supergroups' })}
                  render={response => (
                    <Sidebar
                      menuData={delve(response, 'data.supergroups')}
                      onChangeActiveGroups={onChangeActiveGroups}
                      activeGroupIds={activeGroupIds}
                    />
                  )}
                />
                <Main>
                  <DataFetcher
                    url={createApiUrl({
                      path: 'request_general_indicators',
                      request: apiRequest,
                    })}
                    render={trendData => {
                      const trendScores = delve(trendData, 'data.scores');
                      return (
                        <Fragment>
                          <Header
                            trendScores={trendScores}
                            startDate={startDate}
                            endDate={endDate}
                            submitDates={this.submitDates}
                            organization={data.organization}
                          />

                          <DataFetcher
                            url={createApiUrl({
                              path: 'request_scores',
                              request: apiRequest,
                              count,
                            })}
                            render={scoresData => {
                              const scores = delve(scoresData, 'data.scores');
                              let annotationIds =
                                scores &&
                                retrieveAnnotationIdsFromScores(scores);
								
							

                              return (
                                <Fragment>
                                  {scores && (
                                    <LineChartWrapper
                                      scores={scores}
									  connections={connections}
                                      editModeEnabled={editModeEnabled}
                                      onEditModeClick={this.toggleEditMode}									  
                                      onLineConfigurationToggle={
                                        this.handleLineConfigurationToggle
                                      }
                                      lineConfiguration={lineConfiguration}
                                      annotationIds={annotationIds}
                                      clickedNode={clickedNode}
                                      increaseCount={this.increaseCount}
                                      dataFetcherCounter={count}
                                      clearClickedNode={this.clearClickedNode}
                                      selectedAnnotation={selectedAnnotation}
                                      onChangeSelectedAnnotation={
                                        onChangeSelectedAnnotation
                                      }
                                      requestGroups={requestGroups}
                                    />
                                  )}
                                  {scores && trendScores && (
                                    <CirclePackingWrapper
                                      scores={scores}
                                      trendScores={trendScores}
                                    />
                                  )}
                                </Fragment>
                              );
                            }}
                          />
                        </Fragment>
                      );
                    }}
                  />
                </Main>
              </GroupContext.Provider>
            </Container>
          );
        }}
      />
    );
  }
}

/**
 * We use the addUrlProps higher-order component to map URL query parameters
 * to props for MainPage. In this case the mapping happens automatically by
 * first decoding the URL query parameters based on the urlPropsQueryConfig.
 * @see: https://peterbeshai.com/react-url-query/docs/api/
 */
export default addUrlProps({ urlPropsQueryConfig })(Home);

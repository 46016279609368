import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

import Home from 'pages/Home';
import Login from 'pages/Login';
import Mfa from 'pages/Mfa';
import ForgotPass from 'pages/ForgotPass';
import ErrorPage from 'pages/ErrorPage';

class AppRoutes extends Component {
  render() {
    return (
      <ErrorBoundary>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <Route exact path="/login" render={props => <Login {...props} />} />		  
		  <Route exact path="/mfa" component={Mfa} />		  
		  <Route exact path="/forgotpass" component={ForgotPass} />
          <Route
            exact
            path="/error"
            render={props => <ErrorPage {...props} />}
          />
          <Route
            render={props => (
              <ErrorPage
                {...props}
                status={404}
                error="We could not find the page you're looking for."
              />
            )}
          />
        </Switch>
      </ErrorBoundary>
    );
  }
}
export default AppRoutes;

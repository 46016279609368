import React from 'react';
import { objectOf, array, number, func, string } from 'prop-types';
import styled from 'styled-components/macro';
import delve from 'dlv';
import moment from 'moment';

import { withGroupContext } from 'context/groupContext';
import DateControl from 'components/DateControl/DateControl';
import TrendIndicator from 'components/TrendIndicator/TrendIndicator';

const Gradient = styled('header')`
  position: relative;
  align-items: center;
  color: white;
  padding: 1em;

  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.base.Teal},
    ${({ theme }) => theme.base.Turquoise}
  );
`;

const ClientName = styled('h1')`
  font-size: 1.5em;
  line-height: 1;
  margin: 0;
`;

const KeenCorpIndex = styled('dl')`
  font-size: 0.8em;
`;

const Number = styled('dd')`
  display: inline-flex;
  align-items: center;
`;

const Label = styled('dt')`
  display: inline;
`;

const IndexNumber = styled('span')`
  font-family: ${({ theme }) => theme.numbersFontFamily};
  font-style: italic;
  font-weight: 700;
  line-height: 1;
`;

const StyledTrendIndicator = styled(TrendIndicator)`
  margin-top: 1.1em;
  margin-left: 0.5em;
`;

export const WhiteBar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.base.Mystic};
  min-height: 4em;
`;

function Header(props) {
  const { trendScores, startDate, endDate, submitDates, organization } = props;

  const index = delve(trendScores, 'All.current');
  const trend = delve(trendScores, 'All.trend');

  return (
    <>
      <Gradient>
        <ClientName>{organization}</ClientName>
      </Gradient>
      <WhiteBar>
        <KeenCorpIndex>
          <Label>Latest KeenCorp Index: </Label>
          <Number>
            <IndexNumber>{index}</IndexNumber>
            <StyledTrendIndicator trend={trend} width={20} />
          </Number>
        </KeenCorpIndex>
        <DateControl
          startDate={moment.unix(startDate)}
          endDate={moment.unix(endDate)}
          submitDates={submitDates}
          hasDatePresets
          hasHeader
          showDefaultInputIcon
        />
      </WhiteBar>
    </>
  );
}

Header.propsTypes = {
  trendScores: objectOf(array).isRequired,
  startDate: number.isRequired,
  endDate: number.isRequired,
  submitDates: func.isRequired,
  organization: string.isRequired,
};

export default withGroupContext(Header);

import React from 'react';
import { func, number, bool, object } from 'prop-types';

import ModalWrapper from './ModalWrapper/ModalWrapper';
import { withGroupContext } from '../../context/groupContext';

import delve from 'dlv';

import DataFetcher from 'components/DataFetcher/DataFetcher';
import AnnotationsTable from 'components/AnnotationModal/AnnotationsTable';
import createApiUrl from 'helpers/createApiUrl';

const AllAnnotationsModal = props => {
  const {
    handleSelectAnnotation,
    increaseCount,
    dataFetcherCounter,
    hasData,
    startDate,
    endDate,
    requestGroups,
    setShowAllAnnotations,
    userHasFullAccess,
  } = props;

  let annotationGroups = { ...requestGroups };

  return (
    <ModalWrapper
      handleClose={() => {
        setShowAllAnnotations(false);
      }}
    >
      <DataFetcher
        url={createApiUrl({
          path: 'request_annotations_range',
          request: {
            groups: annotationGroups,
            time_selection: {
              from: startDate,
              to: endDate,
            },
          },
          count: dataFetcherCounter,
        })}
        render={annotationsData => {
          const annotationList = delve(annotationsData, 'data.annotations');

          if (hasData) {
            return (
              <AnnotationsTable
                handleSelectAnnotation={handleSelectAnnotation}
                annotationList={annotationList}
                increaseCount={increaseCount}
                userHasFullAccess={userHasFullAccess}
                setShowAllAnnotations={setShowAllAnnotations}
              />
            );
          }
          return null;
        }}
      />
    </ModalWrapper>
  );
};

AllAnnotationsModal.propTypes = {
  setShowAllAnnotations: func.isRequired,
  handleSelectAnnotation: func.isRequired,
  increaseCount: func.isRequired,
  dataFetcherCounter: number.isRequired,
  hasData: bool,
  startDate: number,
  endDate: number,
  requestGroups: object,
  userHasFullAccess: bool,
};

export default withGroupContext(AllAnnotationsModal);

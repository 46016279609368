import React from 'react';
import { string, number, object } from 'prop-types';

export function TrendArrow({ fill, trend }) {
  if (!trend || trend === 0) return null;
  let iconPath = null;

  if (trend > 1) {
    // double arrow up
    iconPath = 'M7 1L1 11h12zM7 13L1 23h12z';
  } else if (trend === 1) {
    // single arrow up
    iconPath = 'M7 1L1 11h12z';
  } else if (trend === -1) {
    // single arrow down
    iconPath = 'M7 11l6-10H1z';
  } else if (trend < -1) {
    // double arrow down
    iconPath = 'M7 11l6-10H1zM7 23l6-10H1z';
  }

  return <path fillRule="evenodd" d={iconPath} fill={fill} />;
}

export function TrendIndicator({ className, fill, width, trend, style }) {
  if (!trend || trend === 0) return null;

  const originalWith = 14;
  let iconHeight = Math.round((width * 24) / originalWith); // Based on ratio of icon

  return (
    <div style={{ width, height: iconHeight, ...style }} className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 14 24'}>
        <TrendArrow fill={fill} trend={trend} />
      </svg>
    </div>
  );
}

TrendIndicator.defaultProps = {
  fill: 'white',
  width: 12,
};

TrendIndicator.propTypes = {
  className: string,
  trend: number,
  width: number,
  fill: string,
  style: object,
};

export default TrendIndicator;

import React, { Component } from 'react';
import { arrayOf, objectOf, string, bool } from 'prop-types';
import styled from 'styled-components/macro';
import { ellipsis } from 'polished';
import { includes } from 'lodash';

import themeColors from 'styles/theme/colors';
import { withGroupContext } from 'context/groupContext';
import convertGroupIdToLabel from 'helpers/convertGroupIdToLabel';

import Tooltip from '@material-ui/core/Tooltip';
import Warning from '@material-ui/icons/Warning';
import getColorById from '../../helpers/getColorById';

const Group = styled.div`
  display: inline-block;
  width: 6em;
  height: 3em;
  margin-right: 1.2em;
  ${({ isEmpty }) => isEmpty && 'cursor: help'};
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 1.5em;
`;

const Label = styled.label`
  display: block;
  font-size: 0.75em;
  line-height: 1.5;
  color: ${({ theme }) => theme.base.PastelBlue};
  ${ellipsis('100%')};
`;

const StyledWarningIcon = styled(Warning)`
  && {
    margin-left: 0.5em;
    font-size: 1.2em;
    color: ${({ theme }) => theme.base.PastelBlue};
  }
`;

const SVG = styled.svg.attrs({
  style: {
    width: '100%',
    height: '10px',
  },
})``;

const Line = styled.line.attrs(({ theme, color }) => ({
  style: {
    stroke: color || theme.base.PastelBlue,
  },
}))``;

export class Legend extends Component {
  render() {
    const {
      className,
      colorById,
      showReference,
      referenceId,
      groupsWithData,
      groupIds,
    } = this.props;

    return (
      <div className={className}>
        {groupIds.map((id, index) => {
          // Hide the reference group if id matches and showReference is false
          if (id === referenceId && !showReference) return null;

          /**
           * Determine if the legend item contains any data, if not show a
           * warning icon and show a gray line instead of a colored version.
           */
          let isEmpty = false;
          if (includes(groupsWithData, id)) {
            isEmpty = false;
          } else {
            isEmpty = true;
          }

          const label = convertGroupIdToLabel(id);

          if (isEmpty) {
            return (
              <Tooltip
                key={id}
                title="No data within this date range"
                placement="top"
              >
                <Group isEmpty={isEmpty}>
                  <LabelWrapper>
                    <Label isEmpty={isEmpty} title={label}>
                      {label}
                    </Label>
                    <StyledWarningIcon />
                  </LabelWrapper>
                  <SVG
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="10"
                  >
                    <Line
                      color={themeColors.base.Casper}
                      strokeDasharray={id === referenceId ? '1, 4' : null}
                      x1={2}
                      y1={2}
                      x2={98}
                      y2={2}
                    />
                  </SVG>
                </Group>
              </Tooltip>
            );
          }

          return (
            <Group key={id}>
              {!isEmpty && (
                <LabelWrapper>
                  <Label title={label}>{label}</Label>
                </LabelWrapper>
              )}
              <SVG xmlns="http://www.w3.org/2000/svg" width="100" height="10">
                <Line
                  color={getColorById(colorById, id)}
                  strokeDasharray={id === referenceId ? '1, 4' : null}
                  strokeWidth="3px"
                  strokeLinecap="round"
                  shapeRendering="geometricPrecision"
                  x1={2}
                  y1={2}
                  x2={98}
                  y2={2}
                />
              </SVG>
            </Group>
          );
        })}
        {!showReference && groupsWithData.length === 1 && <Group />}
      </div>
    );
  }
}

Legend.defaultProps = {
  groupsWithData: [],
};

Legend.propTypes = {
  className: string,
  referenceId: string.isRequired,
  groupsWithData: arrayOf(string),
  groupIds: arrayOf(string),
  showReference: bool,
  colorById: objectOf(string).isRequired,
};

export default withGroupContext(Legend);

import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import colors from 'styles/theme/colors';
import 'canvas-toBlob';

/**
 * This function creates a PNG image from a DOM element. It first creates
 * a canvas and then saves that as a PNG image.
 *
 * @export
 * @param {string} selector Query selector for DOM element we want to export
 * @param {string} filename Filename we want to generate
 */
export default function saveSvgToImage(selector, filename) {
  // Get DOM element
  const element = document.querySelector(selector);

  // Set margins
  const margin = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20,
  };

  // Set options
  const options = {
    logging: true,
    backgroundColor: colors.base.Smoke,
    x: element.offsetLeft - margin.left,
    y: element.offsetTop - margin.top,
    width: element.clientWidth + margin.left + margin.right,
    height: element.clientHeight + margin.top + margin.bottom,
    scale: 2,
  };

  // Call image render function and save as image
  html2canvas(element, options).then(canvas => {
    return canvas.toBlob(blob => {
      return saveAs(blob, filename);
    });
  });
}

import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { func, node } from 'prop-types';
import { rgba } from 'polished';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { withGroupContext } from '../../../context/groupContext';
import colors from 'styles/theme/colors';

const StyledButton = styled.button`
  text-decoration: underline;
  font-size: 0.625em;
  text-transform: uppercase;
  color: ${colors.base.PastelBlue};
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

const Modal = styled.aside`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => rgba(theme.base.DarkBlue, 0.9)};
  z-index: 99;
  display: flex;
  flex-direction: column;
  padding: 3em;
`;

const Header = styled.div`
  display: flex;
  margin-top: -2.5em;
  margin-bottom: 2em;
  justify-content: flex-start;
  align-items: center;
`;

const Content = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  padding: 5em;
  background-color: ${({ theme }) => theme.base.Smoke};
`;

const CloseButton = styled.button`
  margin-right: -3em;
  display: flex;
  margin-left: auto;
  cursor: pointer;
`;

const modalRoot = document.getElementById('modal');

/** React Portal that serves as a wrapper for the SortByModal and FilterModal */
const ModalWrapper = ({
  handleClose,
  setShowAllAnnotations,
  showLinkToAllAnnotations,
  children,
  ...rest
}) => {
  const modal = React.useRef(null);

  const [modalElement] = React.useState(document.createElement('div'));

  /**
   * Set a class to the html element to remove the default scrollbar
   */
  React.useLayoutEffect(() => {
    document.documentElement.classList.add('annotation-modal-open');
    modalRoot.appendChild(modalElement);

    return () => {
      document.documentElement.classList.remove('annotation-modal-open');
      modalRoot.removeChild(modalElement);
    };
  }, [modalElement]);

  /**
   * Make sure the modal closes when the user presses ESC
   */
  React.useEffect(() => {
    function handleEscKey(event) {
      if (event.keyCode === 27) {
        handleClose();
      }
    }

    window.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [handleClose]);

  /**
   * Make sure the modal closes when the user clicks on the blue background of the modal
   */
  function handleOutsideClick(event) {
    if (event.target === modal.current) {
      handleClose();
    }
  }

  return (
    // Use a portal to render the children into the element
    ReactDOM.createPortal(
      <Modal ref={modal} onClick={handleOutsideClick}>
        <Content smoothScrolling>
          <Header>
            {showLinkToAllAnnotations && (
              <StyledButton
                onClick={() => {
                  handleClose();
                  setShowAllAnnotations(true);
                }}
              >
                View all Annotations
              </StyledButton>
            )}
            <Tooltip title="Close" placement="top">
              <CloseButton onClick={handleClose}>
                <CloseIcon />
              </CloseButton>
            </Tooltip>
          </Header>
          {children}
        </Content>
      </Modal>,
      modalElement
    )
  );
};

export default withGroupContext(ModalWrapper);

ModalWrapper.propTypes = {
  /** Called when the modal is being closed by the user */
  handleClose: func.isRequired,
  /** Either SortByModal or FilterModal  */
  children: node.isRequired,
};

import React, { Component } from 'react';
import { string, object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import logoutUser from 'helpers/logoutUser';
import { Redirect } from 'react-router-dom';

/**
 * This data fetcher is created based on knowledge of previous projects.
 * It is mainly ment for retrieving data and pass it to its children. There
 * are helper functions for handling POST calls and sending data to the API.
 * You can find those in the '/helpers' folder. An example of a helper is
 * '/helpers/createAnnotation.js'.
 *
 * @class DataFetcher
 * @extends {Component}
 */
class DataFetcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: false,
      data: null,
      error: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.fetchData();
    }
  }

  async fetchData() {
    const options = { ...this.props.options, credentials: 'same-origin' };
    const url = this.props.useMockUrl || this.props.url;
    const newFetch = fetch(url, options);

    // Start fetching and set state to loading
    this.setState({ loading: true });

    try {
      const response = await newFetch;

      if (response.ok) {
        const data = await response.json();

        if (data.success) {
          this.setState({
            loading: false,
            success: true,
            data,
            error: null,
          });
        } else if (data && !data.success) {
          this.setState({
            loading: false,
            success: data.success,
            error: data.description,
          });

          /**
           * Log out an user when we receive a falsy success response from the API.
           * The session of an user expires after 30min of inactiveness. After the
           * session expires the server will return a 200 OK together with falsy
           * success response (eventually this should change into 401 UNAUTHORIZED
           * or similar).
           */
          logoutUser()
            .then(response => {
              this.props.history.push('/login');
            })
            .catch(error => {
              console.error(error);
            });
        }
      }
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        success: false,
        error,
      });
    }
  }

  render() {
    const { render: renderFn } = this.props;
    const { error } = this.state;

    /**
     * When the state contains an error and this error is not based on the error
     * we get after 30min of inactiveness redirect to the error page.
     */
    if (error) {
      return (
        <Redirect
          to={{
            ...this.props.location,
            pathname: '/error',
            state: { from: this.props.location },
          }}
        />
      );
    }

    if (renderFn) {
      return renderFn({ ...this.state });
    }

    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, { ...this.state })
    );
  }
}

DataFetcher.propTypes = {
  useMockUrl: string,
  url: string.isRequired,
  options: object,
};

export default withRouter(DataFetcher);

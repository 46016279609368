import React, { Fragment } from 'react';
import { arrayOf, string, objectOf, object, array } from 'prop-types';
import styled from 'styled-components/macro';
import { pack, hierarchy } from 'd3';
import { debounce } from 'lodash';

import { withGroupContext } from 'context/groupContext';

import CircleNodes from './CircleNodes/CircleNodes';
import CirclePackingTooltip from './CirclePackingTooltip/CirclePackingTooltip';

import mapData from './mapData';

const ChartContainer = styled('div')`
  flex: 1 1 auto;
  min-width: 0%;
  height: 100%;
`;

const margin = {
  top: 5,
  right: 5,
  bottom: 5,
  left: 5,
};

const aspectRatio = 1;
const padding = 10;
const maxHeight = 700 - margin.top - margin.bottom;

function CirclePackingChart(props) {
  const { scores, trendScores, groupsWithData, colorById } = props;

  const [size, setSize] = React.useState({
    width: 600 - margin.left - margin.right,
    height: maxHeight,
  });

  const [hoveredNode, setHoveredNode] = React.useState(null);

  const chartContainer = React.useRef(null);

  const setSizes = React.useCallback(() => {
    setSize(size => {
      let newWidth;

      if (chartContainer.current) {
        newWidth = chartContainer.current.getBoundingClientRect().width;
      }

      if (!newWidth || newWidth === size.width) {
        return size;
      }

      /**
       * Set height based on the aspect ratio and prevent it from being
       * bigger than the maxHeight.
       */
      const newHeight = Math.floor(
        newWidth * aspectRatio - margin.top - margin.bottom
      );

      return {
        width: Math.floor(newWidth - margin.left - margin.right),
        height: newHeight > maxHeight ? maxHeight : newHeight,
      };
    });
  }, []);

  const handleResize = React.useMemo(() => debounce(setSizes, 200), [setSizes]);

  const handleHover = React.useCallback(setHoveredNode, []);

  const handleMouseLeave = React.useCallback(() => {
    setHoveredNode(null);
  }, []);

  const nodes = React.useMemo(() => {
    function generateNodes(groupIds, scores, trendScores) {
      const sumScores = mapData(groupIds, scores, trendScores);

      const layout = pack()
        .size([size.width, size.height])
        .padding(padding);

      const root = hierarchy(sumScores).sum(d => d.data.value);

      layout(root);

      return root.descendants();
    }

    return generateNodes(groupsWithData, scores, trendScores, size);
  }, [groupsWithData, scores, size, trendScores]);

  const filteredNodes = React.useMemo(
    () => nodes.filter(({ data: { id } }) => id !== 'container'),
    [nodes]
  );

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  React.useEffect(setSizes, []);

  return (
    <ChartContainer ref={chartContainer}>
      <Fragment>
        {nodes && size.width && size.height && (
          <CircleNodes
            size={size}
            margin={margin}
            handleHover={handleHover}
            handleMouseLeave={handleMouseLeave}
            nodes={filteredNodes}
            colorById={colorById}
          />
        )}
        {hoveredNode && (
          <CirclePackingTooltip node={hoveredNode} margin={margin} />
        )}
      </Fragment>
    </ChartContainer>
  );
}

CirclePackingChart.propTypes = {
  groupsWithData: arrayOf(string).isRequired,
  scores: objectOf(array),
  trendScores: objectOf(object).isRequired,
  colorById: objectOf(string).isRequired,
};

export default withGroupContext(CirclePackingChart);

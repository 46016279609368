import React from 'react';
import { object, arrayOf, func } from 'prop-types';
import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import moment from 'moment';

const Container = styled.div`
  padding: 0 13px 13px;

  button:not(:last-child) {
    margin-right: 0.5em;
  }
`;

/**
 * @see https://github.com/airbnb/react-dates/blob/master/src/utils/isSameDay.js
 */
function isSameDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return (
    a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
  );
}

const DatePresets = ({ startDate, endDate, presets, onDatesChange }) => {
  return (
    <Container>
      {presets.map(({ text, start, end }) => {
        const isSelected =
          isSameDay(start, startDate) && isSameDay(end, endDate);

        const newDates = {
          startDate: start,
          endDate: end,
        };

        return (
          <Button
            key={text}
            type="button"
            color={isSelected ? 'secondary' : 'primary'}
            onClick={() => onDatesChange(newDates)}
          >
            {text}
          </Button>
        );
      })}
    </Container>
  );
};

DatePresets.propTypes = {
  startDate: object.isRequired,
  endDate: object.isRequired,
  presets: arrayOf(object).isRequired,
  onDatesChange: func.isRequired,
};

export default DatePresets;

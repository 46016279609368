import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import {
  defaultButtonStyle,
  labelStyle,
  discreteButtonStyle,
} from '../../styles/base-classes';

export const Section = styled.section`
  padding: 2em;
`;

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;

export const Title = styled.h2`
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.06em;
`;

export const Placeholder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LineChartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ResetZoomButton = styled(Button)`
  && {
    ${defaultButtonStyle};
    ${labelStyle};
    min-height: 2.625rem;
    margin-top: 1em;
    float: right;

    &:hover,
    &:active {
      color: white;
    }
  }
`;
export const ViewAllAnnotationsButton = styled(Button)`
  ${discreteButtonStyle};
`;

/**
 * This conversion script is provided by KeenCorp in oder to translate a "supergroups"
 * API response to a nested JSON structure which can be used to generate a nested
 * menu.
 */

// Converts the flat supergroup structure into a fully nested object structure
function supergroupsToNestedStructure(supergroups) {
  const nested = {
    id: '<root>',
    display_name: '<root>',
    groups: [],
    children: {},
  };
  for (const group in supergroups) {
    if (group.indexOf(':') > -1) {
      const group_split = group.split(':');

      let entry_point = nested;

      for (let i = 0; i < group_split.length; i++) {
        if (!(group_split[i] in entry_point['children'])) {
          const current_id = group_split.slice(0, i + 1).join(':');
          entry_point['children'][group_split[i]] = {
            id: current_id,
            display_name: group_split[i],
            groups: [],
            children: {},
          };
          if (current_id in supergroups) {
            entry_point['children'][group_split[i]]['request_param'] = {};
            entry_point['children'][group_split[i]]['request_param'][
              current_id
            ] = supergroups[current_id];
          }
        }

        for (const gidx in supergroups[group]) {
          entry_point['children'][group_split[i]]['groups'].push(
            supergroups[group][gidx]
          );
        }

        entry_point = entry_point['children'][group_split[i]];
      }
    }
  }
  return nested;
}

// Recursive procedure for convertion of a fully nested structure into the desired Clever Franke format
function nestedStructureToCleverFrankeFormat(
  nested_entrypoint,
  cf_format_entrypoint
) {
  if (nested_entrypoint) {
    var child_keys = Object.keys(nested_entrypoint);

    child_keys.sort(); // Order stuff alphabetically. Feel free to adapt this if different sorting is desired.

    for (let key in child_keys) {
      key = child_keys[key];
      cf_format_entrypoint.push({
        id: nested_entrypoint[key]['id'],
        display_name: nested_entrypoint[key]['display_name'],
        groups: [],
        request_param: nested_entrypoint[key]['request_param'],
      });
      nestedStructureToCleverFrankeFormat(
        nested_entrypoint[key]['children'],
        cf_format_entrypoint[cf_format_entrypoint.length - 1]['groups']
      );
    }
  }
}

export default function convertGroupsToMenuItems(apiDataStructure) {
  const menuItems = [];
  const nested = supergroupsToNestedStructure(apiDataStructure);

  // Create datastructure and call recursive procedure to produce the Clever Franke desired format
  nestedStructureToCleverFrankeFormat(nested['children'], menuItems);

  return menuItems;
}

import React, { Component } from 'react';
import { arrayOf, string } from 'prop-types';
import styled from 'styled-components/macro';

import Legend from 'components/Legend/Legend';

import { ReactComponent as ExplanationSVG } from './explanation.svg';

const LegendWrapper = styled.div`
  flex: 0 1 15rem;
  margin-right: 1em;
`;

const StyledExplanationSVG = styled(ExplanationSVG)`
  text {
    font-weight: 100;
  }
`;

const Subtitle = styled.h3.attrs(({ theme }) => ({
  style: {
    fontFamily: theme.defaultFontFamily,
    fontSize: '0.875rem',
    fontWeight: '700',
    lineHeight: '1.2',
    paddingBottom: '0.5em',
    marginBottom: '1.2em',
    borderBottom: `1px solid ${theme.base.Casper}`,
  },
}))``;

const DefinitionList = styled.dl`
  display: flex;
  align-items: end;
  padding-bottom: 1.2em;
  margin-bottom: 1.2em;
  border-bottom: 1px solid ${({ theme }) => theme.base.Casper};
`;

const DefinitionWrapper = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  &:first-child {
    margin-right: 2em;
  }
`;

const Term = styled.dt`
  display: flex;
  align-items: center;
  height: 5em;
  padding-bottom: 1em;
`;

const Detail = styled.dd`
  font-size: 0.75em;
  line-height: 1.3;
`;

const StyledLegend = styled(Legend)`
  display: grid;
  grid-template-columns: 6em 6em;
  grid-column-gap: 2em;
  align-items: end;
`;

const Explanation = styled.div`
  width: 240px;
  height: auto;
  margin-bottom: 1.2em;
  padding-bottom: 1.2em;
  border-bottom: 1px solid ${({ theme }) => theme.base.Casper};
`;

export class CirclePackingLegend extends Component {
  render() {
    return (
      <LegendWrapper>
        <Subtitle>Legend</Subtitle>
        <DefinitionList>
          <DefinitionWrapper>
            <Term>
              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="64">
                <title>Circle size</title>
                <desc>
                  The size of the circle represents the number of messages. The
                  bigger the number the larger the circle.
                </desc>
                <g
                  fill="none"
                  fillRule="evenodd"
                  stroke="#B3CAE6"
                  transform="translate(2 2)"
                >
                  <ellipse cx="30.19" cy="30" rx="30.19" ry="30" />
                  <ellipse
                    cx="53.32"
                    cy="30.39"
                    opacity=".32"
                    rx="22.74"
                    ry="22.6"
                  />
                  <ellipse
                    cx="79.98"
                    cy="30.39"
                    opacity=".17"
                    rx="16.47"
                    ry="16.36"
                  />
                </g>
              </svg>
            </Term>
            <Detail>
              Circle size = total number of messages over measured period
            </Detail>
          </DefinitionWrapper>
          <DefinitionWrapper>
            <Term>
              <svg xmlns="http://www.w3.org/2000/svg" width="58" height="35">
                <title>Circle color</title>
                <desc>
                  The color of the circle represents the group based on your
                  selection.
                </desc>
                <g fill="none" fillRule="evenodd">
                  <path
                    fill="#B9C7D9"
                    d="M17.47 33.73c9.1 0 16.46-7.33 16.46-16.37C33.93 8.33 26.56 1 17.47 1 8.37 1 1 8.33 1 17.36c0 9.04 7.37 16.37 16.47 16.37z"
                  />
                  <path
                    fill="#E6E8EB"
                    d="M40.47 33.73c9.1 0 16.46-7.33 16.46-16.37C56.93 8.33 49.56 1 40.47 1 31.37 1 24 8.33 24 17.36c0 9.04 7.37 16.37 16.47 16.37z"
                  />
                </g>
              </svg>
            </Term>
            <Detail>Circle color = represents group</Detail>
          </DefinitionWrapper>
        </DefinitionList>
        <Explanation>
          <StyledExplanationSVG />
        </Explanation>
        <StyledLegend
          groupIds={this.props.groupIds}
          groupsWithData={this.props.groupsWithData}
          showReference
        />
      </LegendWrapper>
    );
  }
}

CirclePackingLegend.propTypes = {
  groupsWithData: arrayOf(string).isRequired,
  groupIds: arrayOf(string),
};

export default CirclePackingLegend;

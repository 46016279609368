export default {
  base: {
    Smoke: '#F8F9FC',
    Mystic: '#E1E8EF',
    Casper: '#B9C7D9',
    PastelBlue: '#434D7E',
    DarkBlue: '#1E2B51',
    Blue: '#4886FF',
    Teal: '#00777C',
    Turquoise: '#57A4A7',
  },
  data: {
    DarkBlue: '#2824D8',
    AccentGreen: '#46EBD8',
    LightBlue: '#34BDFF',
    DarkGreen: '#239A72',
    Yellow: '#F8CA11',
    Red: '#E65F56',
    Geen: '#7BD702',
  },
  dataOrdered: [
    '#4886FF',
    '#46EBD8',
    '#2824D8',
    '#34BDFF',
    '#239A72',
    '#F8CA11',
    '#E65F56',
    '#7BD702',
  ],
};

import { has, every } from 'lodash';

/**
 * Check if array of keys exists in object.
 *
 * @param {array} keys Lis of keys to validate
 * @param {object} data Data object in which keys need to exists
 */
export default function keysExistsInObject(keys, data) {
  return every(keys.map(key => has(data, key)));
}

import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import loginUserMFA from 'helpers/loginUserMFA';
import getUserProfile from 'helpers/getUserProfile';

const Container = styled('div')`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  text-align: center;
  color: white;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.base.Blue},
    ${({ theme }) => theme.base.DarkBlue}
  );
`;

const Wrapper = styled('div')`
  margin-bottom: 3em;
`;

const StyledTextField = styled(TextField)`
  && {
    margin: 0.25em 0;
    min-width: 12.5em;

    label {
      font-family: ${({ theme }) => theme.defaultFontFamily};
      color: ${({ theme }) => theme.base.Casper};
    }
    div {
      &:before,
      &:after {
        border-color: white !important;
      }
      &:hover {
        &:before,
        &:after {
          border-color: white !important;
        }
      }
      input {
        font-family: ${({ theme }) => theme.defaultFontFamily};
        color: white;
      }
    }
  }
`;

const LoginButton = styled(Button)`
  && {
    font-size: 0.75em;
    font-weight: 400;
    color: white;
    border-color: white;
    border-radius: 2px;
    text-transform: none;
    transition: all 150ms ease-out;

    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.base.Blue};
      border-color: ${({ theme }) => theme.base.Blue};
    }
  }
`;

const Title = styled('h1')`
  font-size: 1.5em;
  font-weight: 400;
`;

/**
 * This is the landing page when an user is not authenticated.
 * This page shows a login form and handles a log in response
 * from the API. If the login succeeds the user is redirected
 * to the Homepage.
 */
class Mfa extends Component {
  constructor(props) {
    super(props);
	this.state = {
      isAuthenticated: Boolean(getUserProfile().sessionId),
      error: null,
      credentials: {
        mfa_code: null
      },
    };
  }

  handleSubmit = async event => {
    // Prevent this form from submitting on the default way
    event.preventDefault();

    const response = await loginUserMFA({
      credentials: this.state.credentials,
    });

    // Update state based on the response of an user login
    this.setState({
      isAuthenticated: response.success,
      error: response.error,
      payload: response.payload,
    });
  };

  handleChange(key, value) {
    this.setState({
      credentials: {
        ...this.state.credentials,
        [key]: value,
      },
    });
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };

    /**
     * When redirecting use the from prop either retrieved from the PrivateRoute
     * redirect or by a default value "/".
     */
	if (this.state.isAuthenticated) {
		console.log(this.state);
      return <Redirect to={from} />;
    }

    return (
      <Container>
        <Title>KeenCorp</Title>
        <form
          action=""
          noValidate
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <Wrapper>
            <div>
              <StyledTextField
                id="mfa_code"
                type="password"
                autoComplete="mfa"
                label="Mfa"
                error={this.state.error}
                onChange={event =>
                  this.handleChange(
                    'mfa_code',
                    encodeURIComponent(event.target.value)
                  )
                }
              />
            </div>
          </Wrapper>
          {this.state.error && <p>{this.state.payload}</p>}
          <LoginButton type="submit" variant="outlined">
            Login
          </LoginButton>
        </form>
      </Container>
    );
  }
}

export default Mfa;

import React, { Component } from 'react';
import { node, instanceOf } from 'prop-types';
import ErrorPage from 'pages/ErrorPage';

/**
 * The ErrorBoundary component catches errors that are thrown further down in the tree.
 * Error boundaries cannot catch errors that occur in the component where componentDidCatch is defined
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: props.error,
    };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return <ErrorPage className={this.props.className} {...this.state} />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
  error: instanceOf(Error),
};

export default ErrorBoundary;

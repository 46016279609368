import React from 'react';
import { shape, string, number, object } from 'prop-types';
import styled from 'styled-components/macro';
import moment from 'moment';
import { Hint } from 'react-vis';

import Tooltip from 'components/Tooltip/Tooltip';
import convertGroupIdToLabel from 'helpers/convertGroupIdToLabel';

import { ReactComponent as CommentSVG } from 'icons/comment.svg';

const Label = styled('label')`
  display: block;
  font-size: 0.625em;
  font-weight: 400;
`;

const Title = styled('h4')`
  font-size: 1em;
  font-weight: 700;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Term = styled.span`
  flex: 0 0 3.5em;
  margin-right: 1em;
  font-size: 0.8em;
  font-weight: 400;
  text-transform: uppercase;
  text-align: right;
  white-space: nowrap;
`;

export const Details = styled.span`
  font-family: ${({ theme }) => theme.numbersFontFamily};
  min-width: 4ch;
`;

const CommentIcon = styled(CommentSVG)`
  width: 20px;

  g {
    fill: white;
  }
`;

export default function LineChartTooltip({ value, color, ...rest }) {
  return (
    <Hint
      value={value}
      align={{ vertical: 'top', horizontal: 'left' }}
      {...rest}
    >
      <Tooltip color={color}>
        <Label>{moment(value.date * 1000).format('YYYY-MM-DD')}</Label>
        <Title>{convertGroupIdToLabel(value.id)}</Title>
        <Row>
          <Term>KCI:</Term>
          <Details>{Math.round(value.score)}</Details>
        </Row>
        <Row>
          <Term>
            <CommentIcon />
          </Term>
          <Details>{value.amount}</Details>
        </Row>
        <Row>
          <Term>N-size:</Term>
          <Details>{value.sources || 'N/A'}</Details>
        </Row>
      </Tooltip>
    </Hint>
  );
}

LineChartTooltip.propTypes = {
  value: shape({
    date: number.isRequired,
    id: string.isRequired,
    x: object.isRequired,
    y: number.isRequired,
  }).isRequired,
  color: string,
};

import React from 'react';
import {
  arrayOf,
  string,
  objectOf,
  array,
  number,
  bool,
  func,
  shape,
} from 'prop-types';
import { desaturate, mix, rgba } from 'polished';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  Highlight,
  CustomSVGSeries,
  HorizontalRectSeries,
  Borders,
} from 'react-vis';
import moment from 'moment';
import 'react-vis/dist/style.css';

import { withGroupContext } from 'context/groupContext';
import colors from 'styles/theme/colors';

import LineChartTooltip from './LineChartTooltip/LineChartTooltip';

import { axes, gridLines, defaultLineSeries } from './LineChart.style';
import getColorById from '../../helpers/getColorById';
import isLegacyBrowser from '../../helpers/isLegacyBrowser';
import mapData from './helpers/mapData';

export const MIN_ZOOM_DOMAIN = 10;

const size = {
  height: 400,
};

const margin = {
  top: 20,
  right: 60,
  bottom: 20,
  left: 0,
};

const LineChart = props => {
  const {
    annotationIsBeingCreated,
    dateRangeNewAnnotation,
    groupsWithData,
    startDate,
    endDate,
    scores,
    colorById,
    lineConfiguration,
    brushLocation,
    onBrushDrag,
    onBrushEnd,
    showReference,
    referenceId,
    hasBrush,
    activeAnnotation,
  } = props;

  const [hoveredNode, setHoveredNode] = React.useState(null);
  const [hoveredSeries, setHoveredSeries] = React.useState(null);

  /**
   * Disable the hovered node tooltip and the hovered series once the user leaves the LineChart with his mouse
   */
  function handleMouseLeave() {
    setHoveredNode(null);
    setHoveredSeries(null);
  }

  /**
   * Sets the hovered node when a user hovers over a specific value in one of the chart lines
   */
  function handleHover(value, configuration) {
    if (
      hoveredSeries &&
      value.id === hoveredSeries.id &&
      hoveredSeries.configuration === configuration
    ) {
      setHoveredNode(value);
    }
  }

  /**
   * Set x value of horizontalRectSeriesData to define which part of the graph must be highlighted
   * if one of the annotations is hovered or a new annotation is being created
   */
  const horizontalRectSeriesData = React.useMemo(() => {
    if (annotationIsBeingCreated || activeAnnotation) {
      const { from, to } = annotationIsBeingCreated
        ? dateRangeNewAnnotation
        : activeAnnotation.time_selection;

      return {
        x: moment.unix(from).valueOf(),
        x0: moment.unix(to).valueOf(),
      };
    }
    return null;
  }, [activeAnnotation, annotationIsBeingCreated, dateRangeNewAnnotation]);

  /**
   * Activates a chart line when the user hovers over it
   */
  function handleSeriesMouseOver(id, index, configuration) {
    setHoveredSeries({ id, index, configuration });
  }

  /**
   * Check if a tooltip should be visible. The value it represents needs to be in the visible area.
   */
  const showHoveredNode = React.useMemo(() => {
    let show = Boolean(hoveredNode);

    /** Only show the Tooltip if it falls between the zoom section */
    if (brushLocation && hoveredNode) {
      if (
        hoveredNode.y < brushLocation.bottom ||
        hoveredNode.y > brushLocation.top
      ) {
        show = false;
      }
    }

    return show;
  }, [brushLocation, hoveredNode]);

  /** Find the maxValue in the data to use in the y domain */
  const maxValue = React.useMemo(() => {
    const maxValuePerGroup = [];

    // Loop through all groups and check what the maximum value per group is.
    groupsWithData.forEach(group => {
      const groupMaxValue = scores[group].reduce(
        (acc, group) => Math.max(acc, group.score),
        0
      );

      maxValuePerGroup.push(groupMaxValue);
    });

    // Get the absolute max value of all the groups
    return Math.max(...maxValuePerGroup);
  }, [groupsWithData, scores]);

  /**
   * The xDomain is based on the startDate and endDate of the global date picker
   */
  const xDomain = React.useMemo(() => {
    return [moment.unix(startDate).valueOf(), moment.unix(endDate).valueOf()];
  }, [endDate, startDate]);

  /**
   * Calculates the yDomain based on the brush location.
   * If no brushLocation is set fall back to the default values
   */
  const yDomain = React.useMemo(
    () =>
      brushLocation ? [brushLocation.bottom, brushLocation.top] : [0, maxValue],
    [brushLocation, maxValue]
  );

  /**
   * Maps the data into a format used by react-vis
   */
  const trendsBasedOnLineConfig = React.useMemo(() => {
    const trends = [];

    lineConfiguration.forEach(configuration => {
      let trend = { configuration: configuration };
      groupsWithData.forEach(key => {
        trend[key] = mapData({
          data: scores[key],
          id: key,
          xKey: 'date',
          yKey: 'score',
          lineConfiguration: configuration,
          xDomain,
        });
      });
      trends.push(trend);
    });
    return trends;
  }, [groupsWithData, lineConfiguration, scores, xDomain]);

  const isEmptyChart =
    !showReference && groupsWithData && groupsWithData.length === 1;

  const allowBrushing =
    hasBrush && !isLegacyBrowser && yDomain[1] - yDomain[0] > MIN_ZOOM_DOMAIN;

  return (
    <div>
      <FlexibleWidthXYPlot
        id="chart-container"
        xType="time-utc"
        xDomain={xDomain}
        yDomain={yDomain}
        height={size.height}
        margin={margin}
        onMouseLeave={handleMouseLeave}
      >
        <HorizontalGridLines style={gridLines} />
        <VerticalGridLines tickTotal={8} style={gridLines} />
        {isEmptyChart && (
          /** Display an empty rect when there are no groups to show. This makes sure the grid will be shown as a placeholder */
          <CustomSVGSeries
            data={[
              {
                x: moment.unix(startDate).valueOf(),
                y: maxValue,
                customComponent: () => <rect />,
              },
            ]}
          />
        )}

        {(activeAnnotation || annotationIsBeingCreated) && (
          /**
           * If one of the annotations is hovered or a new annotation is being created we need to show a light grey background
           * that indicates the annotation period.
           *
           */

          <HorizontalRectSeries
            data={[
              { ...horizontalRectSeriesData, y: yDomain[0], y0: yDomain[1] },
            ]}
            opacity={0.075}
            fill={colors.base.PastelBlue}
          />
        )}

        {groupsWithData &&
          trendsBasedOnLineConfig.map(trend => {
            return groupsWithData.map((key, index) => {
              // Hide reference if needed
              if (!showReference && key === referenceId) return null;

              // Define default line color
              let stroke = getColorById(colorById, key);

              /**
               * When line is hovered and key does not match line id desaturate
               * line and make it less less visible.
               */
              if (hoveredSeries && key !== hoveredSeries.id) {
                stroke = mix(0.8, colors.base.Smoke, desaturate(0.8, stroke));
              }

              // Render all the active group lines
              return (
                <LineSeries
                  key={`line-${key}/configuration-${trend.configuration}`}
                  curve="curveMonotoneX"
                  data={trend[key]}
                  onSeriesMouseOver={() =>
                    handleSeriesMouseOver(key, index, trend.configuration)
                  }
                  onNearestX={value => {
                    handleHover(value, trend.configuration);
                  }}
                  style={{
                    ...defaultLineSeries,
                    strokeDasharray: key === referenceId ? '1, 4' : null,
                    stroke,
                    strokeWidth: 3,
                    pointerEvents: 'stroke',
                    transition: 'stroke 150ms ease-in-out',
                  }}
                />
              );
            });
          })}

        <Borders
          style={{
            left: { fill: 'none' },
            right: { fill: 'none' },
            bottom: { fill: rgba(colors.base.Smoke, 0.8) },
            top: { fill: rgba(colors.base.Smoke, 0.8) },
          }}
        />

        <XAxis
          attr="x"
          attrAxis="x"
          orientation="bottom"
          position="start"
          tickTotal={8}
          tickSize={0}
          style={axes}
        />

        <YAxis
          attr="y"
          attrAxis="y"
          orientation="right"
          tickSize={0}
          style={axes}
        />

        {showHoveredNode && (
          <LineChartTooltip
            value={hoveredNode}
            color={getColorById(colorById, hoveredSeries.id)}
          />
        )}

        {allowBrushing && (
          /** Only allow zooming when the domain is bigger than the minZoomDomain */
          <Highlight
            enableX={false}
            highlightWidth={margin.right}
            highlightX={xDomain[1]}
            onDrag={onBrushDrag}
            onBrushEnd={onBrushEnd}
            color={colors.base.Blue}
          />
        )}
      </FlexibleWidthXYPlot>
    </div>
  );
};

LineChart.defaultProps = {
  annotations: [],
  annotationSlotCount: 0,
};

LineChart.propTypes = {
  referenceId: string.isRequired,
  groupsWithData: arrayOf(string).isRequired,
  colorById: objectOf(string).isRequired,
  scores: objectOf(array),
  startDate: number.isRequired,
  endDate: number.isRequired,
  showReference: bool,
  lineConfiguration: arrayOf(string).isRequired,
  brushLocation: shape({
    top: number.isRequired,
    bottom: number.isRequired,
  }),
  onBrushEnd: func.isRequired,
  onBrushDrag: func.isRequired,
  hasBrush: bool,
};

export default withGroupContext(LineChart);

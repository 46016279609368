import moment from 'moment';

export const today = moment().endOf('day');

export function getPresets(firstDate) {
  return [
    {
      text: 'Last month',
      start: moment().subtract(1, 'month'),
      end: today,
    },
    {
      text: 'Last quarter',
      start: moment().subtract(3, 'month'),
      end: today,
    },
    {
      text: 'Last 6 months',
      start: moment().subtract(6, 'month'),
      end: today,
    },
    {
      text: 'Last year',
      start: moment().subtract(1, 'year'),
      end: today,
    },
    {
      text: 'Everything',
      start: moment.unix(firstDate),
      end: today,
    },
  ];
}

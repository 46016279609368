import Cookies from 'js-cookie';
import createApiUrl from 'helpers/createApiUrl';

/**
 * Log an user out and remove all related cookies from the browser.
 */
export default async function() {
  const url = createApiUrl({ path: 'logout' });

  function removeCookies() {
    [
      'full_access',
      'name',
      'organization',
      'organization_friendly',
      'role',
      'session_id',
      'success',
    ].forEach(key => {
      Cookies.remove(key);
    });
  }

  try {
    const response = await fetch(url, { method: 'GET' });

    if (response.ok) {
      const { success, description } = await response.json();
      removeCookies();

      if (!success) {
        console.error(description);
      }
    }
    console.error({ response });
  } catch (error) {
    console.error({ error });
  }
}

import React, { Component } from 'react';
import { objectOf, array, string, object } from 'prop-types';
import styled from 'styled-components/macro';
import { toLower, isEmpty, keys } from 'lodash';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CirclePackingChart from 'components/CirclePackingChart/CirclePackingChart';
import CirclePackingLegend from 'components/CirclePackingLegend/CirclePackingLegend';

import { withGroupContext } from 'context/groupContext';
import saveSvgToImage from 'helpers/saveSvgToImage';

import { ReactComponent as ExportSVG } from 'icons/export.svg';

import { ReactComponent as PlaceholderSVG } from './placeholderChart.svg';
import { iconButtonStyle } from 'styles/base-classes';

const Section = styled('section')`
  padding: 2em;
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;

const Title = styled.h2`
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.06em;
`;

const ExportIcon = styled(ExportSVG)`
  transition: all 150ms ease-out;
  stroke: ${({ theme }) => theme.base.PastelBlue};
`;

const ExportButton = styled(Button)`
  && {
    ${iconButtonStyle};
    border-radius: 3px 0 0 3px;
  }
`;

const Wrapper = styled.div`
  display: flex;
`;

const Placeholder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export class CirclePackingWrapper extends Component {
  /**
   * Call helper function to create an export of the SVG (based on
   * its id) and name it according to the active groups.
   */
  handleExportButtonClick = () => {
    const groupIdsString = toLower(keys(this.props.scores).join('-'));
    saveSvgToImage(
      '#CirclePacking',
      `keencorp-circle-packing-${groupIdsString}.png`
    );
  };

  render() {
    const { scores, trendScores, referenceId } = this.props;
    const scoreKeys = keys(scores);

    /**
     * Filter all from groups when we have more than one group
     * retrieved, because we only use all as reference when nothing
     * is selected.
     */
    const filteredGroups = scoreKeys.filter(
      key => !(key === referenceId && scoreKeys.length > 1)
    );

    /**
     * Check whether or not the data is valid in order to render the chart
     * or show a placeholder wit no data message instead.
     * Loop over the groups that should be active and check if they contain
     * data. If not filter them out of the list.
     */
    const groupsWithData = filteredGroups.filter(
      id => !isEmpty(scores[id]) && !isEmpty(trendScores[id])
    );
    const hasData = groupsWithData.length >= 1;

    return (
      <Section>
        <ChartHeader>
          <Title>Messages per group</Title>
          <Tooltip title="Export chart" placement="top">
            <ExportButton
              variant="contained"
              onClick={this.handleExportButtonClick}
            >
              <ExportIcon />
            </ExportButton>
          </Tooltip>
        </ChartHeader>
        <Wrapper id="CirclePacking">
          <CirclePackingLegend
            groupsWithData={groupsWithData}
            groupIds={filteredGroups}
          />
          {!hasData && (
            <Placeholder>
              <Content>
                <p>We could not retrieve data for this period.</p>
              </Content>
              <PlaceholderSVG />
            </Placeholder>
          )}
          {hasData && (
            <CirclePackingChart
              groupsWithData={groupsWithData}
              scores={scores}
              trendScores={trendScores}
            />
          )}
        </Wrapper>
      </Section>
    );
  }
}

CirclePackingWrapper.propTypes = {
  referenceId: string.isRequired,
  scores: objectOf(array).isRequired,
  trendScores: objectOf(object).isRequired,
};

export default withGroupContext(CirclePackingWrapper);

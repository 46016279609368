import React from 'react';
import { shape, string, number } from 'prop-types';
import styled from 'styled-components/macro';

import Tooltip from 'components/Tooltip/Tooltip';
import TrendIndicator from 'components/TrendIndicator/TrendIndicator';
import convertGroupIdToLabel from 'helpers/convertGroupIdToLabel';
import {
  Term,
  Details,
  Row,
} from '../../LineChart/LineChartTooltip/LineChartTooltip';

import { ReactComponent as CommentSVG } from 'icons/comment.svg';

const Container = styled.div`
  position: absolute;
  pointer-events: none;
  transform: translate(-100%, -100%);
`;

const Title = styled.h4`
  font-size: 1em;
  font-weight: 700;
`;

const CommentIcon = styled(CommentSVG)`
  width: 20px;

  g {
    fill: white;
  }
`;

const StyledTrendIndicator = styled(TrendIndicator)`
  margin-left: 0.2em;
`;

export default function CirclePackingTooltip({
  node: { id, x, y, score, value, color, trend, sources },
}) {
  return (
    <Container
      style={{
        top: `${y}px`,
        left: `${x}px`,
      }}
    >
      <Tooltip color={color} showPoint={false}>
        <Title>{convertGroupIdToLabel(id)}</Title>
        <Row>
          <Term>KCI:</Term>
          <Details>{score}</Details>
          <StyledTrendIndicator trend={trend} width={10} />
        </Row>
        <Row>
          <Term>
            <CommentIcon />
          </Term>
          <Details>{value}</Details>
        </Row>
        <Row>
          <Term>N-size:</Term>
          <Details>{sources || 'N/A'}</Details>
        </Row>
      </Tooltip>
    </Container>
  );
}

CirclePackingTooltip.propTypes = {
  node: shape({
    id: string.isRequired,
    x: number.isRequired,
    y: number.isRequired,
    score: number.isRequired,
    value: number.isRequired,
    color: string,
  }).isRequired,
};

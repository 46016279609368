import React from 'react';
import { bool, arrayOf, string } from 'prop-types';
import styled from 'styled-components/macro';

import Legend from 'components/Legend/Legend';

const StyledLegend = styled(Legend)`
  flex: 1 1 auto;
  align-items: end;
  margin-top: 1em;
  margin-right: 1em;
  display: flex;
  flex-wrap: wrap;
`;

export function LineChartLegend(props) {
  return <StyledLegend {...props} />;
}

LineChartLegend.propTypes = {
  showReference: bool,
  groupsWithData: arrayOf(string),
  groupIds: arrayOf(string),
};

export default LineChartLegend;

import Cookies from 'js-cookie';
import createApiUrl from 'helpers/createApiUrl';

/**
 * Log in an user and sets response as cookies.
 *
 * @param {object} credentials User username and password in order to log in
 */
export default async function({ credentials }) {
  const url = createApiUrl({ path: 'mfa_login', request: credentials });
  const result = {
    success: false,
    error: false,
    payload: null,
  };

  const setCookies = keyValues => {
    // Temp add values to cookies till we have data from the API
    const tempCookieKeys = {
      reference_id: 'All',
    };
    const newKeyValues = { ...keyValues, ...tempCookieKeys };

    // Loop over all keys and set them to cookies
    Object.keys(newKeyValues).forEach(key => {
      Cookies.set(key, newKeyValues[key]);
    });
  };

  try {
    const response = await fetch(url, { method: 'GET' });

    if (response.ok) {
      const payload = await response.json();

      if (payload.success) {
        setCookies(payload);
		console.log(payload);
		if ('mfa' in payload){
			result.mfa = true;
		};
		
        return {
          ...result,
          success: true,
          error: false,
          payload,
        };
      }

      return {
        ...result,
        success: false,
        error: true,
        payload: payload.description,
      };
    }

    return {
      ...result,
      success: false,
      error: true,
      payload: response,
    };
  } catch (error) {
    console.error({ error });
    return {
      ...result,
      success: false,
      error: true,
      payload: error,
    };
  }
}

import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import getUserProfile from 'helpers/getUserProfile';

export class PrivateRoute extends Component {
  state = {
    isAuthenticated: false,
    userHasFullAccess: false,
  };

  checkAuthenticationStatus() {
    const userProfile = getUserProfile();
    const loginStatus = Boolean(userProfile.sessionId);
    const userHasFullAccess = userProfile.userHasFullAccess === 'true';

    if (loginStatus !== this.state.isAuthenticated) {
      this.setState({ isAuthenticated: loginStatus, userHasFullAccess });
    }
  }

  componentWillMount() {
    this.checkAuthenticationStatus();
  }

  componentWillReceiveProps() {
    this.checkAuthenticationStatus();
  }

  render() {
    const { isAuthenticated, userHasFullAccess } = this.state;
    const { component: Component, ...rest } = this.props;

    if (isAuthenticated) {
      return (
        <Route
          {...rest}
          render={props => (
            <Component {...props} userHasFullAccess={userHasFullAccess} />
          )}
        />
      );
    }
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              ...this.props.location,
              pathname: '/login',
              state: { from: this.props.location },
            }}
          />
        )}
      />
    );
  }
}

export default PrivateRoute;

import React from 'react';
import { arrayOf, bool, object, func } from 'prop-types';
import styled from 'styled-components/macro';
import { withGroupContext } from '../../context/groupContext';
import moment from 'moment';
import colors from 'styles/theme/colors';
import removeAnnotation from '../../helpers/removeAnnotation';
import Button from '@material-ui/core/Button';
import { discreteButtonStyle } from 'styles/base-classes';
import PasteHtml from 'components/PasteHtml/PasteHtml';

import {
  RemoveButton,
  RemoveIcon,
} from 'components/ButtonGroup/ButtonGroup.style';

import Tooltip from '@material-ui/core/Tooltip';

const ViewButton = styled(Button)`
  ${discreteButtonStyle};
`;

const StyledAnnotationsTable = styled.table`
  width: 100%;

  tbody {
    width: 100%;
    overflow: hidden;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button {
    margin-left: 0.7em;
  }
`;

const Row = styled.tr`
  width: 100%;
  td,
  th {
    padding: 0.7em;
    :not(:last-child) {
      min-width: 220px;
      max-width: 600px;
    }
  }
  :nth-child(odd) {
    td {
      margin: 0;
      background: ${colors.base.Mystic};
    }
  }
`;

const AnnotationBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 500px;
  height: 100%;
`;

const HeaderRow = styled(Row)`
  text-align: left;
  text-transform: uppercase;
`;

const AnnotationsTable = props => {
  const {
    userHasFullAccess,
    annotationList,
    increaseCount,
    handleSelectAnnotation,
    setShowAllAnnotations,
  } = props;

  const [sortedAnnotations, setSortedAnnotations] = React.useState([]);

  /**
   * Removes an annotation once the user clicks the remove button.
   */
  function handleRemoveAnnotation(annotation) {
    if (window.confirm(`Do you want to remove "${annotation.title}"?`)) {
      const updatedSortedAnnotations = [...sortedAnnotations];
      updatedSortedAnnotations.splice(
        updatedSortedAnnotations.indexOf(annotation),
        1
      );
      setSortedAnnotations(updatedSortedAnnotations);
      removeAnnotation(annotation._id)
        // Increase the counter to make sure DataFetcher loads the new scores
        .then(increaseCount);
    }
  }

  function byStartDate(a, b) {
    return a.time_selection.from - b.time_selection.from;
  }

  React.useEffect(() => {
    if (annotationList) setSortedAnnotations(annotationList.sort(byStartDate));
  }, [annotationList]);

  return (
    <>
      <h2>All Annotations</h2>
      <StyledAnnotationsTable>
        <thead>
          <HeaderRow>
            <th>Date Range</th>
            <th>Annotation Title</th>
            <th>Annotation</th>
            <th> </th>
          </HeaderRow>
        </thead>
        <tbody>
          {sortedAnnotations.map(annotation => {
            return (
              <Row key={annotation._id}>
                <td>
                  {moment
                    .unix(annotation.time_selection.from)
                    .format('YYYY-MM-DD')}{' '}
                  -{' '}
                  {moment
                    .unix(annotation.time_selection.to)
                    .format('YYYY-MM-DD')}
                </td>
                <td>{annotation.title}</td>
                <td>
                  <AnnotationBody>
                    <span>
                      {typeof annotation.short_description === 'string' ? (
                        <>{annotation.short_description}</>
                      ) : (
                        <PasteHtml
                          ValueAsJson={annotation.short_description}
                        ></PasteHtml>
                      )}
                    </span>
                    <Wrapper>
                      <Tooltip title="View Annotation" placement="top">
                        <ViewButton
                          variant="contained"
                          onClick={() => {
                            setShowAllAnnotations(false);
                            handleSelectAnnotation(annotation._id);
                          }}
                        >
                          view
                        </ViewButton>
                      </Tooltip>
                      {userHasFullAccess && (
                        <Tooltip title="Remove annotation" placement="top">
                          <RemoveButton
                            variant="contained"
                            onClick={() => handleRemoveAnnotation(annotation)}
                          >
                            <RemoveIcon />
                          </RemoveButton>
                        </Tooltip>
                      )}
                    </Wrapper>
                  </AnnotationBody>
                </td>
              </Row>
            );
          })}
        </tbody>
      </StyledAnnotationsTable>
    </>
  );
};

AnnotationsTable.propTypes = {
  userHasFullAccess: bool,
  annotationList: arrayOf(object),
  increaseCount: func.isRequired,
  handleSelectAnnotation: func.isRequired,
  setShowAllAnnotations: func.isRequired,
};

export default withGroupContext(AnnotationsTable);

import createApiUrl from 'helpers/createApiUrl';

export default async function removeAnnotation(id) {
  const url = createApiUrl({
    path: 'remove_annotation',
    request: { annotation: id },
  });
  const result = {
    success: false,
    error: false,
    payload: null,
  };

  try {
    const response = await fetch(url, { method: 'GET' });

    if (response.ok) {
      const payload = await response.json();

      if (payload.success) {
        return {
          ...result,
          success: true,
          error: false,
          payload,
        };
      }

      return {
        ...result,
        success: false,
        error: true,
        payload: payload.description,
      };
    }

    return {
      ...result,
      success: false,
      error: true,
      payload: response,
    };
  } catch (error) {
    console.error({ error });
    return {
      ...result,
      success: false,
      error: true,
      payload: error,
    };
  }
}

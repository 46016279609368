import colors from 'styles/theme/colors';
import { REFERENCE_ID } from '../config';

function getColorById(colorById, id) {
  if (id === REFERENCE_ID) {
    return colors.dataOrdered[0];
  }

  // If no color is found (new data is being loaded) make it lightgray.
  return colorById[id] || colors.base.Mystic;
}

export default getColorById;

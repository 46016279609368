import React from 'react';
import { func, objectOf, string } from 'prop-types';
import { keys, first, isEmpty } from 'lodash';
import delve from 'dlv';
import moment from 'moment';
import styled from 'styled-components/macro';

import ModalWrapper from './ModalWrapper/ModalWrapper';
import LineChart from '../LineChart/LineChart';
import LineChartLegend from '../LineChartLegend/LineChartLegend';
import { Title } from '../LineChartWrapper/LineChartWrapper.style';
import createApiUrl from '../../helpers/createApiUrl';
import DataFetcher from '../DataFetcher/DataFetcher';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup';
import { withGroupContext } from '../../context/groupContext';
import removeAnnotation from '../../helpers/removeAnnotation';
import assignNewColorsToGroups from '../../helpers/assignNewColorsToGroups';
import PasteHtml from 'components/PasteHtml/PasteHtml';


const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Description = styled.div`
  margin-top: 3em;
`;

/**
 * Shows a modal with a single annotation visible in the LineChart
 */
const AnnotationModal = props => {
  const {
    lineConfiguration,
    onLineConfigurationToggle,
    setShowAllAnnotations,
    selectedAnnotation,
    handleCloseAnnotation,
    userHasFullAccess,
    handleExport,
    referenceId,
    increaseCount,
    colorById,
  } = props;

  return (
    <ModalWrapper
      handleClose={handleCloseAnnotation}
      setShowAllAnnotations={setShowAllAnnotations}
      showLinkToAllAnnotations={true}
    >
      <DataFetcher
        url={createApiUrl({
          path: 'request_annotations',
          request: {
            annotations: [selectedAnnotation],
          },
        })}
        render={annotationsData => {
			
          const annotation = first(
            delve(annotationsData, 'data.annotations', [])
          );
		  

          /**
           * Removes an annotation once the user clicks the remove button.
           */
          function handleRemoveAnnotation(id) {
            if (
              window.confirm(`Do you want to remove "${annotation.title}"?`)
            ) {
              removeAnnotation(id)
                .then(() => increaseCount())
                .then(handleCloseAnnotation);
            }
          }

          if (!annotation) {
            return null;
          }
		  console.log(annotation);
		  

          /**
           * Build API request to retrieve scores and trend indicator data.
           */
          const apiRequest = {
            from: annotation.time_selection.from,
            to: annotation.time_selection.to,
            groups: annotation.groups,
          };

          const extendedColorById = assignNewColorsToGroups(
            colorById,
            annotation.groups
          );

          return (
            <DataFetcher
              url={createApiUrl({
                path: 'request_scores',
                request: apiRequest,
              })}
              render={scoresData => {
                const scores = delve(scoresData, 'data.scores');
                const groupIds = keys(scores);
                const groupsWithData = groupIds.filter(
                  id => !isEmpty(scores[id])
                );
                const hasData = groupsWithData.length >= 1;
								
                return (
                  <>
                    <Header>
                      <div>
                        <Title>{annotation.title}</Title>
                        <time>{`${moment
                          .unix(annotation.time_selection.from)
                          .format('YYYY-MM-DD')} - ${moment
                          .unix(annotation.time_selection.to)
                          .format('YYYY-MM-DD')}`}</time>
                      </div>
                      {hasData && (
                        <ButtonGroup
                          selectedAnnotation={annotation._id}
                          lineConfiguration={lineConfiguration}
                          onExportClick={handleExport}
                          onLineConfigurationToggle={onLineConfigurationToggle}
                          onRemoveAnnotation={handleRemoveAnnotation}
                          userHasFullAccess={userHasFullAccess}
                          referenceId={referenceId}
                          scores={scores}
                        />
                      )}
                    </Header>
                    {hasData && (
                      <div id="annotation-chart">
                        <LineChartLegend
                          groupsWithData={groupsWithData}
                          groupIds={groupIds}
                          colorById={extendedColorById}
                          showReference
                        />
                        <LineChart
                          scores={scores}
                          groupsWithData={groupsWithData}
                          lineConfiguration={lineConfiguration}
                          startDate={annotation.time_selection.from}
                          endDate={annotation.time_selection.to}
                          colorById={extendedColorById}
                          showReference
                        />
                      </div>
                    )}
                    <Description>
                      {typeof annotation.short_description === 'string' ? (
                        <div dangerouslySetInnerHTML={{__html: annotation.payload}} />
						
                      ) : (
                        <PasteHtml
                          ValueAsJson={annotation.short_description}
                        ></PasteHtml>
                      )}
                    </Description>					
                  </>
                );
              }}
            />
          );
        }}
      />
    </ModalWrapper>
  );
};

AnnotationModal.propTypes = {
  selectedAnnotation: string,
  handleCloseAnnotation: func.isRequired,
  handleExport: func.isRequired,
  increaseCount: func.isRequired,
  colorById: objectOf(string).isRequired,
};

export default withGroupContext(AnnotationModal);

import { stratify, sum, max } from 'd3';

const mapData = (keys, scores, trendScores) => {
  return stratify()([
    ...keys.map(key => {
      return {
        id: key,
        label: key,
        parentId: 'container',
        value: sum(scores[key], d => d.amount),
        score: trendScores[key].current,
        trend: trendScores[key].trend,
        sources: max(scores[key], d => d.sources),
      };
    }),
    {
      id: 'container',
      score: null,
    },
  ]);
};

export default mapData;

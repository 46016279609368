import React, { useEffect } from 'react';
import { number, func, string, bool, object } from 'prop-types';
import moment from 'moment';
import { join, omit } from 'lodash';
import Button from '@material-ui/core/Button';

import { withGroupContext } from 'context/groupContext';
import convertGroupIdToLabel from 'helpers/convertGroupIdToLabel';

import DateControl from '../DateControl/DateControl';
import createAnnotation from '../../helpers/createAnnotation';

import SlateEditor from 'components/SlateEditor/SlateEditor';

import {
  IconWrapper,
  PencilIcon,
  Title,
  DateAndGroup,
  ButtonWrapper,
  StyledAnnotationDetails,
  Label,
  TextInput,
  Container,
  Header,
  Content,
} from './CreateAnnotation.style';

import styled from 'styled-components';

const ButtonToggle = styled(Button)`
  opacity: 0.6;
  ${({ active }) =>
    active &&
    `
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;


const CreateAnnotation = props => {
  const {
    activeGroups,
    showReference,
    referenceId,
    activeGroupIds,
    increaseCount,
    onEditModeClick,
    setAnnotationIsBeingCreated,
    setDateRange,
    startDate,
    endDate,
    dateRange,
	connections
  } = props;  
    

  useEffect(() => {
    setAnnotationIsBeingCreated(true);
    return () => {
      setAnnotationIsBeingCreated(false);
    };
  }, [setAnnotationIsBeingCreated]);

  
  const [inputValues, setInputValue] = React.useState({
    title: '',
    shortDescription: '',
	annotationType: 'manual',
  });

  //Sets the new annotation date when the date pickers are used
  function handleChangeAnnotationDate({ startDateMoment, endDateMoment }) {
    setDateRange({
      from: startDateMoment.unix(),
      to: endDateMoment.unix(),
    });
  }

  // Saves input from form to the state
  function handleChangeInputField(key, value) {	
    setInputValue({
      ...inputValues,
      [key]: value,
    });	
  }

  // Saves the new annotation to the API
  function handleCreateAnnotation() {
    const { title, shortDescription,  annotationType} = inputValues;
    /**
     * If reference id is disabled, remove it from the groups when creating a call.
     */
    const groups = showReference
      ? activeGroups
      : omit(activeGroups, referenceId);

    /**
     * Reset date range after new annotation has been created
     */
    setDateRange({
      from: startDate,
      to: endDate,
    });
	
	createAnnotation({
      type: annotationType,
      title: title,
      short_description: shortDescription,
      time_selection: {
        from: dateRange.from,
        to: dateRange.to,
      },
      payload: null,
      groups,
    })
      // Increase the counter to make sure DataFetcher loads the new scores
      .then(increaseCount)
      // Toggles the edit mode off
      .then(onEditModeClick);
  }
  
  // Saves the new annotation to the API
  function handleCreateAnnotation_alt() {
    const { annotationType} = inputValues;
    /**
     * If reference id is disabled, remove it from the groups when creating a call.
     */
    const groups = showReference
      ? activeGroups
      : omit(activeGroups, referenceId);

    /**
     * Reset date range after new annotation has been created
     */
    setDateRange({
      from: startDate,
      to: endDate,
    });
	
	createAnnotation({
      type: annotationType,
      title: '',
      short_description: '',
      time_selection: {
        from: dateRange.from,
        to: dateRange.to,
      },
      payload: null,
      groups,
    })
      // Increase the counter to make sure DataFetcher loads the new scores
      .then(increaseCount)
      // Toggles the edit mode off
      .then(onEditModeClick);
  }

  const groupList = activeGroupIds
    .filter(key => !(!showReference && key === referenceId))
    .map(group => convertGroupIdToLabel(group));
		
  if (inputValues.annotationType==="manual"){	
  return (
    <Container>
      <Header>
        <IconWrapper>
          <PencilIcon />
        </IconWrapper>
        <Title>Create new annotation</Title>
      </Header>
      <Content>
	  
	  <ButtonGroup >
          <ButtonToggle
            onClick={() => handleChangeInputField('annotationType', 'manual')}
            value="manual"
          >
            Manual
          </ButtonToggle>
			
			  {connections.map(connection => {
				  return(
				  <ButtonToggle
					onClick={() => handleChangeInputField('annotationType', connection.type)}
					value={connection.type}
				  >
				  {connection.name}
				  </ButtonToggle>			  
			  )})}	  
        </ButtonGroup>
	  
        <StyledAnnotationDetails>
          <Label>Groups</Label>
          <DateAndGroup>{join(groupList, ', ')}</DateAndGroup>
          <Label>Date range</Label>
          <DateControl
            startDate={moment.unix(dateRange.from)}
            endDate={moment.unix(dateRange.to)}
            submitDates={handleChangeAnnotationDate}
            minimumNights={1}
            type="inline"
          />
          <Label>Annotation Title (Exported Chart Title)</Label>
          <TextInput
            id="add-annotation-title"
            name="add-annotation-title"
            placeholder="Type title here …"
            autoFocus={true}
            value={inputValues.title}
            onChange={event =>
              handleChangeInputField('title', event.target.value)
            }
          />
          <Label>Annotation</Label>
          <SlateEditor handleChangeInputField={handleChangeInputField} />
          <ButtonWrapper css={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCreateAnnotation}
            >
              Add Annotation
            </Button>
          </ButtonWrapper>
        </StyledAnnotationDetails>
      </Content>
    </Container>
  );
};

return (
    <Container>
      <Header>
        <IconWrapper>
          <PencilIcon />
        </IconWrapper>
        <Title>Create new annotation</Title>
      </Header>
      <Content>
	  <ButtonGroup >
          <ButtonToggle
            onClick={() => handleChangeInputField('annotationType', 'manual')}
            value="manual"
          >
            Manual
          </ButtonToggle>
			
			  {connections.map(connection => {
				  return(
				  <ButtonToggle
					onClick={() => handleChangeInputField('annotationType', '{connection.type}')}
					value={connection.type}
				  >
				  {connection.name}
				  </ButtonToggle>			  
			  )})}
	  
	  
        
                    
        </ButtonGroup>
      
        <StyledAnnotationDetails>
          <Label>Groups</Label>
          <DateAndGroup>{join(groupList, ', ')}</DateAndGroup>
          <Label>Date range</Label>
          <DateControl
            startDate={moment.unix(dateRange.from)}
            endDate={moment.unix(dateRange.to)}
            submitDates={handleChangeAnnotationDate}
            minimumNights={1}
            type="inline"
          />          
          
          <ButtonWrapper css={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCreateAnnotation_alt}
            >
              Request Analysis
            </Button>
          </ButtonWrapper>
        </StyledAnnotationDetails>
      </Content>
    </Container>
  );
}


CreateAnnotation.propTypes = {
  activeGroups: object.isRequired,
  startDate: number.isRequired,
  endDate: number.isRequired,
  showReference: bool,
  referenceId: string.isRequired,
  increaseCount: func.isRequired,
};

export default withGroupContext(CreateAnnotation);

import { css } from 'styled-components/macro';
import colors from 'styles/theme/colors';

const typography = css`
  html {
    font-size: 16px;
  }

  body {
    font-family: 'IBM Plex Sans', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-variant: common-ligatures lining-nums contextual;
    line-height: 1.6;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0.75em;
    font-weight: 600;
  }

  h1 {
    font-size: 4em;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.2em;
  }

  a {
    text-decoration-skip: ink;
  }

  /* style text inside of slate-editor */
  [data-slate-editor='true'] {
    h1,
    h2 {
      margin-bottom: 0.3em;
    }
    h1 {
      font-size: 1.7em;
    }
    h2 {
      font-size: 1.2em;
    }
    ul,
    ol {
      list-style-position: inside;
      padding: 0;
      margin: 1em 0;
    }
    ol {
      list-style-type: decimal;
    }
    ul {
      list-style-type: disc;
    }
  }
  /* style placholder inside slate-editor */
  [contenteditable='false'] {
    color: ${colors.base.PastelBlue};
    opacity: 1 !important;
  }
`;

export default typography;

import React from 'react';
import { arrayOf, number, object, func, string } from 'prop-types';
import styled from 'styled-components/macro';
import { CustomSVGSeries, FlexibleWidthXYPlot, Hint } from 'react-vis';
import colors from 'styles/theme/colors';
import AnnotationTooltip from '../AnnotationTooltip/AnnotationTooltip';
import moment from 'moment';
import { labelStyle } from 'styles/base-classes';
import { withGroupContext } from '../../context/groupContext';

export const ANNOTATION_SLOT_HEIGHT = 9;

const Container = styled.div`
  margin-top: 1em;
`;

const Rect = styled.rect`
  cursor: pointer;
`;

const AnnotationLabel = styled.span`
  ${labelStyle};
  color: ${({ theme }) => theme.base.PastelBlue};
  opacity: 0.4;
`;

const margin = {
  top: 0,
  right: 60,
  bottom: 0,
  left: 0,
};

function byStartDate(a, b) {
  return a.time_selection.from - b.time_selection.from;
}

/**
 * Displays the annotations for a specific time period that exist in one of the active groups.
 * All annotations will be rendered in a react-vis SVG
 */
const Annotations = props => {
  const container = React.useRef(null);

  const {
    annotationList,
    handleSelectAnnotation,
    startDate,
    endDate,
    hoveredAnnotation,
    handleHoverAnnotation,
  } = props;

  // Sort the annotations by start date, so that the earliest in date are first in the list
  const sortedAnnotationsList = React.useMemo(
    () => (annotationList ? annotationList.sort(byStartDate) : []),
    [annotationList]
  );

  /**
   * We want to place all the annotations next to each other, if they do not overlap.
   * If they do overlap the next annotation will create a new slot (row) below the previous annotation.
   * If that slot already exists it will be placed in that slot.
   */
  const annotationSlots = React.useMemo(
    () =>
      sortedAnnotationsList.reduce((slots, annotation) => {
        let freeSlotIndex;

        // Walk through all the current slots
        for (let index = 0; index < slots.length; index++) {
          const slot = slots[index];
          // check for every item in the slot if it has already occupied the start location of the annotation
          const hasFreeSpace = slot.every(
            item => annotation.time_selection.from > item.time_selection.to
          );

          // If a free space is found, assign the slot index to freeSlotIndex
          if (hasFreeSpace) {
            freeSlotIndex = index;
            break;
          }
        }

        // If freeSlotIndex is still undefined, it means no fee space was found
        if (freeSlotIndex === undefined) {
          // Create a new slot and push the first annotation in that slot
          slots.push([annotation]);
        } else {
          // If a free slot was found, push the annotation in that slot
          slots[freeSlotIndex].push(annotation);
        }

        return slots;
      }, []),
    [sortedAnnotationsList]
  );

  // Append the slotIndex to the annotation so that we can place it on the yDomain
  const annotations = React.useMemo(
    () =>
      annotationSlots.reduce((acc, slot, slotIndex) => {
        slot.forEach(annotation => acc.push({ ...annotation, slotIndex }));

        return acc;
      }, []),
    [annotationSlots]
  );

  // Calculate the height of the annotation SVG
  const annotationHeight = annotationSlots.length * ANNOTATION_SLOT_HEIGHT;

  // The xDomain is based on the start and endDate of the overall datepicker
  const xDomain = React.useMemo(() => {
    return [moment.unix(startDate).valueOf(), moment.unix(endDate).valueOf()];
  }, [endDate, startDate]);

  // The xDomain contains the slots
  const yDomain = [annotationSlots.length, 0];

  /**
   * Calculates the from, to and center values that are in range of the xDomain
   * This is used to position the annotations and make sure they do not fall off the chart.
   * The center is used to define the Tooltip position.
   */
  const getSanitizedTimeSelection = React.useCallback(
    timeSelection => {
      // Make sure the from and to of the annotation do not exceed the xDomain to calculate the Hint position.
      const from = Math.max(
        moment.unix(timeSelection.from).valueOf(),
        xDomain[0]
      );
      const to = Math.min(moment.unix(timeSelection.to).valueOf(), xDomain[1]);
      // Get the center time value, so that we can center align the Hint
      const center = Math.round((to - from) / 2 + from);

      return { from, to, center };
    },
    [xDomain]
  );

  /**
   * Calculates the width of an annotation based on it's 'from' and 'to' values.
   *
   * @param {Object} timeSelection the time_selection of an annotation coming from the API
   * @param {number} timeSelection.from the start date of the annotation
   * @param {number} timeSelection.to the end date of the annotation
   *
   * @returns {number} the width of the annotation in pixels
   *
   * @todo this function can be removed once this PR that is currently in master is released in a new react-vis version
   * @see https://github.com/uber/react-vis/commit/80a0331e230cd00eedbe2493b0758b2d9298b0ce
   */
  const getWidthByTimeSelection = React.useCallback(
    ({ from, to }) => {
      if (!container.current) {
        return 0;
      }

      const {
        width: containerWidth,
      } = container.current.getBoundingClientRect();

      const chartWidth = containerWidth - margin.left - margin.right;
      const xDomainDelta = xDomain[1] - xDomain[0];
      const timeDelta = to - from;

      return Math.round((chartWidth / xDomainDelta) * timeDelta);
    },
    [xDomain]
  );

  return (
    <Container ref={container}>
      <FlexibleWidthXYPlot
        id="chart-annotations"
        xType="time-utc"
        xDomain={xDomain}
        yDomain={yDomain}
        height={annotationHeight}
        margin={margin}
      >
        {annotations.map(annotation => {
          const timeSelection = getSanitizedTimeSelection(
            annotation.time_selection
          );

          const isHovered = annotation._id === hoveredAnnotation;

          // Render a HorizontalRectSeries per annotation
          return (
            <CustomSVGSeries
              key={annotation._id}
              data={[
                {
                  x: timeSelection.from,
                  y: annotation.slotIndex,
                  customComponent: () => {
                    // Calculate the styling of the annotation.
                    const styleAttributes = {
                      stroke: 'transparent',
                      strokeWidth: ANNOTATION_SLOT_HEIGHT,
                      fill: colors.base.PastelBlue,
                      opacity: isHovered ? 1 : 0.2,
                      height: 5,
                      width: getWidthByTimeSelection(timeSelection),
                    };

                    return (
                      <Rect
                        {...styleAttributes}
                        onMouseEnter={() =>
                          handleHoverAnnotation(annotation._id)
                        }
                        onMouseLeave={() => handleHoverAnnotation(null)}
                        onClick={() => {
                          handleSelectAnnotation(annotation._id);
                          handleHoverAnnotation(null);
                        }}
                      />
                    );
                  },
                },
              ]}
            />
          );
        })}

        {annotations.map(annotation => {
          if (annotation._id === hoveredAnnotation) {
            const { center } = getSanitizedTimeSelection(
              annotation.time_selection
            );

            // Render a tooltip if one of the annotations is hovered.
            return (
              <Hint
                key={annotation._id}
                align={{ horizontal: 'right', vertical: 'top' }}
                value={{
                  x: center,
                  y: annotation.slotIndex,
                }}
              >
                <AnnotationTooltip
                  from={annotation.time_selection.from}
                  to={annotation.time_selection.to}
                  title={annotation.title}
                  groups={annotation.groups}
                />
              </Hint>
            );
          }

          return null;
        })}
      </FlexibleWidthXYPlot>
      <AnnotationLabel transform="translate(0,10)">Annotations</AnnotationLabel>
    </Container>
  );
};

Annotations.propTypes = {
  annotationList: arrayOf(object),
  handleSelectAnnotation: func.isRequired,
  startDate: number.isRequired,
  endDate: number.isRequired,
  hoveredAnnotation: string,
  handleHoverAnnotation: func.isRequired,
};

export default withGroupContext(Annotations);

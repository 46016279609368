import getUserProfile from 'helpers/getUserProfile';

/**
 * Helper to create an API url which uses the session_id cookie
 * in every request in order to check if an user is authenticated.
 */
export default function({
  protocol = 'https',
  subdomain = 'api',
  domain = 'keencorp.com',
  path = '',
  request,
  count,
}) {
  const sessionId = getUserProfile().sessionId;

  const requestWithSessionId = {
    session_id: sessionId,
    ...request,
  };

  let url = `${protocol}://${
    subdomain ? `${subdomain}.` : ''
  }${domain}/${path}?request=${JSON.stringify(requestWithSessionId)}`;

  if (count) {
    url = url + `&count=${count}`;
  }

  return url;
}

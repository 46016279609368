import React, { Component } from 'react';
import { objectOf, arrayOf, array, object, func, string } from 'prop-types';
import { assign, omit, first, keys } from 'lodash';

import { withGroupContext } from 'context/groupContext';

import List from '@material-ui/core/List';
import NestedListItem from './NestedListItem/NestedListItem';

class NestedList extends Component {
  /**
   * Handle click on a checkbox and add that id to the query params.
   * When new params are created call the onChange function provided
   * by the addUrlProps HOC (see: react-url-query).
   */
  activateGroup = (id, requestParam) => {
    // Create new object with the requestParam added
    const newActiveGroups = assign(
      { ...this.props.activeGroups },
      requestParam
    );

    // pass the entire new array to the change handler
    this.props.onChangeActiveGroups(newActiveGroups);
  };

  /**
   * Handle click on a checkbox and remove that id to the query params.
   * When new params are created call the onChange function provided
   * by the addUrlProps HOC (see: react-url-query).
   */
  deactivateGroup = (id, requestParam) => {
    // Create new object with the id removed
    const newActiveGroups = omit({ ...this.props.activeGroups }, id);

    // pass the entire new array to the change handler
    this.props.onChangeActiveGroups(newActiveGroups);
  };

  /**
   * Render method to create a recursive list of nested items.
   */
  renderNestedItems = (item, showCheckbox) => {
    const { activeGroupIds } = this.props;
    /**
     * Use the key of the request_param as key (this is the same key as used
     * in scores data returned form the API). If there is no request_param
     * in case of supergroups, use the id of the item.
     */
    const id = first(keys(item.request_param)) || item.id;

    return (
      <NestedListItem
        key={id}
        id={id}
        displayName={item.display_name}
        requestParam={item.request_param}
        showCheckbox={showCheckbox}
        activeGroupIds={activeGroupIds}
        activateGroup={this.activateGroup}
        deactivateGroup={this.deactivateGroup}
      >
        {item.groups.map(group => {
          return this.renderNestedItems(group, true);
        })}
      </NestedListItem>
    );
  };

  render() {
    const { menuItems } = this.props;

    return (
      <List disablePadding component="nav">
        {menuItems.map(item => {
          return this.renderNestedItems(item, false);
        })}
      </List>
    );
  }
}

NestedList.propTypes = {
  referenceId: string.isRequired,
  activeGroupIds: arrayOf(string).isRequired,
  activeGroups: objectOf(array).isRequired,
  onChangeActiveGroups: func.isRequired,
  menuItems: arrayOf(object).isRequired,
};

export default withGroupContext(NestedList);

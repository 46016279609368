import React, { Component } from 'react';
import { number, func, shape, string, object } from 'prop-types';
import { scaleLinear } from 'd3';
import { mix } from 'polished';

import convertGroupIdToLabel from 'helpers/convertGroupIdToLabel';
import { withGroupContext } from 'context/groupContext';
import colors from 'styles/theme/colors';

import { TrendArrow } from 'components/TrendIndicator/TrendIndicator';

import { Number, Label } from './Circle.style';

const scaleGenerator = scaleLinear()
  .domain([10, 100])
  .range([0.2, 1]);

class Circle extends Component {
  constructor(props) {
    super(props);

    this.scoreRef = React.createRef();
    this.messagesRef = React.createRef();

    const { node, defaultStrokeWidth, color, referenceId } = props;
    const { data } = node;

    // Default values
    let fill = color;
    let stroke = color;
    let strokeDasharray = 0;
    let strokeWidth = defaultStrokeWidth;
    let innerColor = 'white';

    // Show different style for the reference group
    if (data.id === referenceId) {
      fill = colors.base.Blue;
      stroke = colors.base.Blue;
      strokeDasharray = '1, 4';
      strokeWidth = 3;
      innerColor = colors.base.Smoke;
    }

    this.state = {
      innerColor,
      fill,
      stroke,
      strokeWidth,
      strokeDasharray,
      scoresWidth: 0,
      messagesWidth: 0,
    };
  }

  componentDidMount() {
    let scoresWidth = this.state.scoresWidth;
    let messagesWidth = this.state.messagesWidth;

    /**
     * After rendering check the size of text elements and save those in state.
     */
    if (this.scoreRef.current) {
      scoresWidth = this.scoreRef.current.getBoundingClientRect().width;
    }
    if (this.messagesRef.current) {
      messagesWidth = this.messagesRef.current.getBoundingClientRect().width;
    }

    this.setState({
      scoresWidth,
      messagesWidth,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    let scoresWidth = this.state.scoresWidth;
    let messagesWidth = this.state.messagesWidth;

    /**
     * After updating check the size of text elements and save those in state.
     */
    if (this.scoreRef.current) {
      scoresWidth = this.scoreRef.current.getBoundingClientRect().width;
    }
    if (this.messagesRef.current) {
      messagesWidth = this.messagesRef.current.getBoundingClientRect().width;
    }

    /**
     * Only update the state when there is a difference between the actual
     * width and the saved width.
     */
    if (scoresWidth !== prevState.scoresWidth) {
      this.setState({ scoresWidth });
    }
    if (messagesWidth !== prevState.messagesWidth) {
      this.setState({ messagesWidth });
    }
  }

  render() {
    const { node, handleHover } = this.props;
    const {
      innerColor,
      fill,
      stroke,
      strokeWidth,
      strokeDasharray,
      scoresWidth,
      messagesWidth,
    } = this.state;
    const { x, y, r, data } = node;
    const { label, score, value, trend, sources } = data.data;

    let showMetadata = true;
    let showScore = true;
    let transparentBorderWidth = r * 0.2 > 30 ? 30 : r * 0.2;
    const ringOpacity = Math.abs(trend) > 1 ? 1 : 0.2;
    const scale = scaleGenerator(node.r) > 1 ? 1 : scaleGenerator(node.r);
    /**
     * Do not render the container, because it is used
     * in order to create a circle pack one level deep.
     */
    if (data.id === 'container') return null;

    // Hide metadata when radius becomes smaller than 60
    if (r < 60) {
      showMetadata = false;
    }

    // Hide metadata when radius becomes smaller than 20
    if (r < 20) {
      showMetadata = false;
      showScore = false;
    }

    return (
      <g
        id={`${data.id}`}
        transform={`translate(${x},${y})`}
        strokeLinecap="butt"
        onMouseMove={event => {
          handleHover({
            id: data.id,
            x: event.pageX,
            y: event.pageY,
            color: stroke,
            score,
            value,
            trend,
            sources,
          });
        }}
        pointerEvents="none"
      >
        <circle
          id={'stroke'}
          cx={0}
          cy={0}
          r={r}
          fill={'none'}
          stroke={stroke}
          strokeWidth={strokeWidth + 1}
          strokeDasharray={strokeDasharray}
          strokeLinecap={'round'}
          pointerEvents="auto"
        />
        <circle
          id={'fill'}
          cx={0}
          cy={0}
          r={r - transparentBorderWidth / 2 - strokeWidth / 2}
          stroke={fill ? mix(ringOpacity, fill, 'white') : 'none'}
          strokeWidth={transparentBorderWidth}
          fill={innerColor}
          pointerEvents="auto"
        />
        {showScore && (
          <g
            transform={`translate(0, ${
              showMetadata ? -(30 * scale) : -(15 * scale)
            }), scale(${scale})`}
          >
            <Number ref={this.scoreRef}>{score}</Number>
            <g transform={`translate(${scoresWidth / 2 / scale + 2}, 0)`}>
              <TrendArrow trend={trend} fill={colors.base.PastelBlue} />
            </g>
            {showMetadata && (
              <g transform={'translate(0, 45)'}>
                <Label>{convertGroupIdToLabel(label)}</Label>
                <g
                  transform={`translate(-${messagesWidth / 2 / scale + 22}, 7)`}
                  fill={colors.base.PastelBlue}
                  fillRule="nonzero"
                >
                  <path d="M15.2 2.8v10.7l-1.7-1.8H2.8v-9h12.4zM2.6 1C1.7 1 1 1.7 1 2.6v9.2c0 1 .7 1.6 1.6 1.6h10.9L17 17V2.6c0-.9-.7-1.6-1.6-1.6H2.6z" />
                  <path d="M13.4 5H4v1.8h9.4zM13.4 8H4v1.8h9.4z" />
                </g>
                <Label y={20} ref={this.messagesRef}>
                  {value}
                </Label>
              </g>
            )}
          </g>
        )}
      </g>
    );
  }
}

Circle.defaultProps = {
  defaultStrokeWidth: 5,
};

Circle.propTypes = {
  referenceId: string.isRequired,
  defaultStrokeWidth: number,
  handleHover: func.isRequired,
  node: shape({
    data: shape({
      id: string.isRequired,
      data: object,
    }),
    x: number.isRequired,
    y: number.isRequired,
    r: number.isRequired,
  }),
};

export default withGroupContext(Circle);

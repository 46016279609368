import styled, { css } from 'styled-components/macro';
import { mix } from 'polished';
import { labelStyle } from 'styles/base-classes';

export const SubtitleBar = styled.div`
  position: relative;
  top: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;
`;

export const Subtitle = styled.label`
  ${labelStyle};
  width: 130px;
  padding: 0 12px;
  margin-left: 20px;
  color: ${({ theme }) => theme.base.PastelBlue};
`;

export const StyledDateRangePickerWrapper = styled('div')`
  font-weight: 400;
  color: ${({ theme }) => theme.base.PastelBlue};

  strong {
    font-weight: 400;
  }

  /* DateInput */
  .DateInput {
    width: 8em;
    font-size: ${({ type }) => (type === 'inline' ? '0.8125rem' : '1rem')};

    ${({ type }) =>
      type === 'inline' &&
      css`
        width: 7.5em;

        &:first-child {
          margin-right: 0.5em;
        }
        &:last-child {
          margin-left: 0.5em;
        }
      `};
  }
  .DateInput_input {
    padding-top: 9px;
    font-size: 1em;
    font-weight: 400;
    font-family: ${({ theme }) => theme.defaultFontFamily};
    line-height: 1.5em;
    color: ${({ theme }) => theme.base.PastelBlue};
    outline: none;

    ${({ type }) =>
      type === 'inline' &&
      css`
        background-color: ${({ theme }) => theme.base.Smoke};
      `};
  }
  .DateInput_input__focused {
    border-bottom: 2px solid ${({ theme }) => theme.base.Blue};
  }
  /* DateRangePickerInput */
  .DateRangePickerInput__withBorder {
    border-color: ${({ theme }) => theme.base.Mystic};
  }
  .DateRangePickerInput_arrow_svg {
    fill: ${({ theme }) => theme.base.PastelBlue};
  }
  .DateRangePickerInput_calendarIcon {
    transform: translate(
      0,
      -3px
    ); /* Matching space for bottom border of date input*/
  }
  .DateRangePickerInput_calendarIcon_svg {
    fill: ${({ theme }) => theme.base.PastelBlue};
  }

  /* CalendarMonth */
  .CalendarMonth_caption {
    font-size: 1em;
    color: ${({ theme }) => theme.base.PastelBlue};
  }

  /* PresetDateRangePicker */
  .PresetDateRangePicker_button {
    border: 2px solid ${({ theme }) => theme.base.Blue};
    color: ${({ theme }) => theme.base.Blue};
  }
  .PresetDateRangePicker_button__selected {
    background: ${({ theme }) => theme.base.Blue};
  }

  /* DayPickerKeyboardShortcuts  */
  .DayPickerKeyboardShortcuts_show__bottomRight {
    border-right: 33px solid ${({ theme }) => theme.base.Blue};

    &:hover {
      border-right: 33px solid ${({ theme }) => theme.base.Blue};
    }
  }
  .DayPickerKeyboardShortcuts_show__topRight {
    border-right: 33px solid ${({ theme }) => theme.base.Blue};

    & :hover {
      border-right: 33px solid ${({ theme }) => theme.base.Blue};
    }
  }
  .DayPickerKeyboardShortcuts_show__topLeft {
    border-left: 33px solid ${({ theme }) => theme.base.Blue};

    &:hover {
      border-left: 33px solid ${({ theme }) => theme.base.Blue};
    }
  }

  /* CalendarDay */
  .CalendarDay__default {
    color: ${({ theme }) => theme.base.PastelBlue};
  }
  .CalendarDay__selected,
  .CalendarDay__selected_start,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${({ theme }) => theme.base.Blue};
    border: 1px solid ${({ theme }) => theme.base.Blue};
    color: white;

    &.CalendarDay__selected_span {
      background: ${({ theme }) => theme.base.Blue};
      border: 1px solid ${({ theme }) => theme.base.Blue};
      color: white;
    }
  }
  .CalendarDay__selected_span {
    background: ${({ theme }) => mix(0.6, theme.base.Blue, 'white')};
    border: 1px solid ${({ theme }) => mix(0.6, theme.base.Blue, 'white')};
    color: white;

    &:active,
    &:hover {
      background: ${({ theme }) => theme.base.Blue};
      border: 1px solid ${({ theme }) => theme.base.Blue};
      color: white;
    }
  }
  .CalendarDay__last_in_range {
    border-right: ${({ theme }) => theme.base.Blue};
  }
  .CalendarDay__blocked_out_of_range {
    color: ${({ theme }) => theme.base.Casper};
  }
  .CalendarDay__blocked_minimum_nights {
    cursor: not-allowed;

    &:hover {
      background: ${({ theme }) => mix(0.6, theme.base.Blue, 'white')};
      border: 1px solid ${({ theme }) => mix(0.6, theme.base.Blue, 'white')};
    }
  }
  .CalendarDay__hovered_span {
    background: ${({ theme }) => mix(0.6, theme.base.Blue, 'white')};
    border: 1px solid ${({ theme }) => mix(0.6, theme.base.Blue, 'white')};

    &:hover {
      background: ${({ theme }) => theme.base.Blue};
      border: 1px solid ${({ theme }) => theme.base.Blue};
    }
  }

  /* DayPicker_weekHeader */
  .DayPicker_weekHeader_li {
    color: ${({ theme }) => theme.base.PastelBlue};
    text-transform: uppercase;
    letter-spacing: 0.06em;

    small {
      font-size: 0.625em;
    }
  }

  /* Navigation elements */
  .DayPickerNavigation_svg__horizontal {
    pointer-events: none;
  }
`;

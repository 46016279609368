import variables from 'styles/theme/variables';
import colors from 'styles/theme/colors';

export const axes = {
  line: {
    stroke: colors.base.Mystic,
    strokeWidth: 1,
    shapeRendering: 'crispedges',
  },
  ticks: {
    stroke: colors.base.Mystic,
    strokeWidth: 1,
    shapeRendering: 'crispedges',
  },
  text: {
    stroke: 'none',
    fill: colors.base.PastelBlue,
    fontFamily: variables.defaultFontFamily,
    fontSize: '0.625em',
    textTransform: 'uppercase',
    letterSpacing: '0.06em',
    textAnchor: 'start',
    userSelect: 'none',
  },
};

export const gridLines = {
  shapeRendering: 'geometricPrecision',
  stroke: colors.base.Mystic,
  strokeWidth: 1,
};

export const defaultLineSeries = {
  strokeLinecap: 'round',
  fill: 'none',
  strokeWidth: 3,
};

export const defaultMarkSeries = {
  fill: 'black',
};

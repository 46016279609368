import styled from 'styled-components/macro';
import { labelStyle } from 'styles/base-classes';
import { ReactComponent as PencilSVG } from '../../icons/edit.svg';

export const Title = styled.label`
  flex: 1 0 auto;
  padding: 0.25em 1em;
  font-size: 0.8125em;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-align: left;
`;

export const IconWrapper = styled.div`
  flex: 0 0 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em;
`;

export const PencilIcon = styled(PencilSVG)`
  stroke: currentColor;
`;

export const ButtonWrapper = styled.div`
  align-self: center;
  flex: 0 0 auto;
  padding: 0.25em;
  margin-left: auto;
  margin-top: 2em;
  margin-bottom: 1em;
`;

export const StyledListItem = styled.li`
  display: block;
  padding: 0.5em 0.5em 1em;
  border-left: 1px solid ${({ theme }) => theme.base.Mystic};
  border-right: 1px solid ${({ theme }) => theme.base.Mystic};
  border-bottom: 1px solid ${({ theme }) => theme.base.Mystic};
  background: white;
`;

export const Row = styled.div`
  flex: 1 1 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAnnotationDetails = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-flow: column;
  margin: 0 2em;
`;

export const Label = styled.label`
  ${labelStyle};
  margin-top: 2em;
`;

export const TextInput = styled.input`
  margin: 0.25em 0.5em 0.25em 0;
  padding: 0.75em;
  font-size: 0.8125em;
  color: ${({ theme }) => theme.base.PastelBlue};
  border: none;
  background-color: ${({ theme }) => theme.base.Smoke};
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.base.PastelBlue};
  }
`;

export const DateAndGroup = styled.label`
  flex: 1 1 100%;
  font-size: 0.8125em;
  font-weight: 700;
`;

export const Container = styled.section`
  width: 100%;
  padding: 1em 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5em;
  transition: background-color 150ms ease-in-out;
  background-color: ${({ theme }) => theme.base.PastelBlue};
  color: white;
`;

export const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.base.Mystic};
  background-color: white;
  padding: 0.5em;
`;

import React from 'react';
import { string, node, bool } from 'prop-types';
import styled from 'styled-components/macro';
import { transparentize } from 'polished';

const StyledTooltip = styled('div')`
  position: relative;
  margin-bottom: ${({ showPoint }) => (showPoint ? '20px' : '12px')};
`;

const Container = styled('div')`
  padding: 1em;
  color: white;
  background: ${({ color }) => color};
  border-radius: 3px 3px 0 3px;
`;

const Carrot = styled('div')`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: ${({ color }) => `12px solid ${color}`};
  border-left: 12px solid transparent;
  transform: translate(0, 100%);
`;

const Bullet = styled('div')`
  position: absolute;
  bottom: -26px;
  right: -6px;
  width: 12px;
  height: 12px;
  background: white;
  border: 3px solid ${({ color }) => color};
  border-radius: 50%;
  box-shadow: ${({ color }) => `0 0 9px ${transparentize(0.2, color)}`};
`;

export default function Tooltip({ color, showPoint, children }) {
  return (
    <StyledTooltip showPoint={showPoint}>
      <Container color={color}>{children}</Container>
      <Carrot color={color} />
      {showPoint && <Bullet color={color} />}
    </StyledTooltip>
  );
}

Tooltip.defaultProps = {
  color: 'black',
  showPoint: true,
};

Tooltip.propTypes = {
  color: string,
  showPoint: bool,
  children: node.isRequired,
};

/**
 * This detects IE11;
 * @see https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
 *
 * @returns {boolean} wether or not the current browser is IE11
 */
const isLegacyBrowser =
  !!window.MSInputMethodContext && !!document.documentMode;

export default isLegacyBrowser;

function convertToLOESS(data, bandwidth) {
  let xval = data.map(function(d) {
    return d['date'];
  });
  let yval = data.map(function(d) {
    return d['score'];
  });
  let res = loess(xval, yval, bandwidth);
  return data.map(function(item, i) {
    return { ...item, score: res[i] };
  });
}

function loess(xval, yval, bandwidth) {
  function tricube(x) {
    let tmp = 1 - x * x * x;
    return tmp * tmp * tmp;
  }

  let res = [];

  let left = 0;
  let right = Math.floor(bandwidth * xval.length) - 1;

  for (let i in xval) {
    let x = xval[i];

    if (i > 0) {
      if (
        right < xval.length - 1 &&
        xval[right + 1] - xval[i] < xval[i] - xval[left]
      ) {
        left++;
        right++;
      }
    }

    let edge;
    if (xval[i] - xval[left] > xval[right] - xval[i]) edge = left;
    else edge = right;

    let denom = Math.abs(1.0 / (xval[edge] - x));

    let sumWeights = 0;
    let sumX = 0,
      sumXSquared = 0,
      sumY = 0,
      sumXY = 0;

    let k = left;
    while (k <= right) {
      let xk = xval[k];
      let yk = yval[k];
      let dist;
      if (k < i) {
        dist = x - xk;
      } else {
        dist = xk - x;
      }
      let w = tricube(dist * denom);
      let xkw = xk * w;
      sumWeights += w;
      sumX += xkw;
      sumXSquared += xk * xkw;
      sumY += yk * w;
      sumXY += yk * xkw;
      k++;
    }

    let meanX = sumX / sumWeights;
    let meanY = sumY / sumWeights;
    let meanXY = sumXY / sumWeights;
    let meanXSquared = sumXSquared / sumWeights;

    let beta;
    if (meanXSquared === meanX * meanX) beta = 0;
    else beta = (meanXY - meanX * meanY) / (meanXSquared - meanX * meanX);

    let alpha = meanY - beta * meanX;

    res[i] = beta * x + alpha;
  }

  return res;
}

export default convertToLOESS;

// Example from:
// https://github.com/ianstormtaylor/slate/blob/master/examples/components.js

import React from 'react';
import styled from 'styled-components/macro';
import { rgba } from '../../../node_modules/polished/lib/index';

export const Wrapper = styled.div`
  min-height: 10em;
  margin: 0.25em 0.5em 0.25em 0;
  padding: 0.75em;
  font-size: 0.8125em;
  color: ${({ theme }) => theme.base.PastelBlue};
  background-color: ${({ theme }) => theme.base.Smoke};
`;

const StyledButton = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.base.PastelBlue};
  padding: 0.3em 0.3em 0.2em 0.3em;
  border-radius: 0.3em;
  background-color: ${props =>
    props.active ? rgba(67, 77, 126, 0.15) : 'none'};
`;

const StyledEditorValue = styled.div`
  margin: 30px -20px 0;
`;

const StyledIcon = styled.span`
  font-size: 18px;
  vertical-align: text-bottom;
`;

const StyledInstruction = styled.div`
  white-space: pre-wrap;
  margin: 0 -20px 10px;
  padding: 10px 20px;
  font-size: 14px;
  background: #f8f8e8;
`;

const StyledToolbar = styled.div`
  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 15px;
  }

  position: relative;
  padding: 1px 18px 17px;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
`;

const StyledEditorContent = styled.div`
  white-space: pre-wrap;
  padding: 10px 20px;
  div {
    margin: 0 0 0.5em;
  }
`;

export const Button = React.forwardRef(
  ({ className, active, ...props }, ref) => (
    <StyledButton {...props} active={active} ref={ref} className={className} />
  )
);

export const EditorValue = React.forwardRef(
  ({ className, value, ...props }, ref) => {
    const textLines = value.document.nodes
      .map(node => node.text)
      .toArray()
      .join('\n');
    return (
      <StyledEditorValue ref={ref} {...props} className={className}>
        <StyledEditorContent>{textLines}</StyledEditorContent>
      </StyledEditorValue>
    );
  }
);

export const Icon = React.forwardRef(({ className, ...props }, ref) => (
  <StyledIcon {...props} ref={ref} className={'material-icons ' + className} />
));

export const Instruction = React.forwardRef(({ className, ...props }, ref) => (
  <StyledInstruction {...props} ref={ref} className={className} />
));

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <StyledToolbar {...props} ref={ref} className={className} />
));

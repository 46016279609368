import styled from 'styled-components/macro';

export const Number = styled.text.attrs(({ theme }) => ({
  fill: theme.base.PastelBlue,
  textAnchor: 'middle',
  y: 25,
  style: {
    fontFamily: theme.numbersFontFamily,
    fontSize: '2em',
    fontWeight: '700',
  },
}))``;

export const Label = styled.text.attrs(({ theme }) => ({
  fill: theme.base.PastelBlue,
  textAnchor: 'middle',
  style: {
    fontFamily: theme.defaultFontFamily,
    fontWeight: theme.defaultFontWeight,
    fontSize: '0.9em',
  },
}))``;

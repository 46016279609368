import { last, truncate } from 'lodash';

/**
 * This function gets an group id which is a : (colon) separated
 * string of group names. It converts that string into the a
 * display name (label) and therefor always uses the last part
 * of the sting after the last colon as label.
 *
 * @param {string} id Id of the group to covert
 * @param {*} maxLength Maxmum number of characters in the label
 */
export default function convertGroupIdToLabel(id, maxLength = 0) {
  if (!maxLength) {
    return last(id.split(':'));
  }
  return truncate(last(id.split(':')), { length: maxLength });
}

import React, { useEffect } from 'react';
import {
  arrayOf,
  objectOf,
  array,
  string,
  func,
  bool,
  object,
  number,
} from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toLower, isEmpty, keys } from 'lodash';
import delve from 'dlv';

import { withGroupContext } from 'context/groupContext';

import DataFetcher from 'components/DataFetcher/DataFetcher';
import LineChart, { MIN_ZOOM_DOMAIN } from 'components/LineChart/LineChart';
import Annotations from 'components/Annotations/Annotations';
import AllAnnotationsModal from 'components/AnnotationModal/AllAnnotationsModal';
import LineChartLegend from 'components/LineChartLegend/LineChartLegend';
import ButtonGroup from 'components/ButtonGroup/ButtonGroup';

import saveSvgToImage from 'helpers/saveSvgToImage';
import createApiUrl from 'helpers/createApiUrl';
import { ReactComponent as PlaceholderSVG } from './placeholderChart.svg';

import {
  Section,
  ChartHeader,
  Content,
  LineChartHeader,
  Placeholder,
  ResetZoomButton,
  Title,
  ViewAllAnnotationsButton,
} from './LineChartWrapper.style';
import AnnotationModal from '../AnnotationModal/AnnotationModal';
import CreateAnnotation from '../CreateAnnotation/CreateAnnotation';
import { REFERENCE_ID } from '../../config';

const LineChartWrapper = props => {
  const {
    scores,
	connections,
    editModeEnabled,
    onEditModeClick,
    onLineConfigurationToggle,
    lineConfiguration,
    annotationIds,
    increaseCount,
    dataFetcherCounter,
    selectedAnnotation,
    onChangeSelectedAnnotation,
    startDate,
    endDate,
    requestGroups,
  } = props;

  const [showReference, setShowReference] = React.useState(true);
  const [brushLocation, setBrushLocation] = React.useState(null);
  const [hoveredAnnotation, setHoveredAnnotation] = React.useState(null);
  const [showAllAnnotations, setShowAllAnnotations] = React.useState(false);
  const [
    annotationIsBeingCreated,
    setAnnotationIsBeingCreated,
  ] = React.useState(false);
  const [dateRangeNewAnnotation, setDateRangeNewAnnotation] = React.useState({
    from: startDate,
    to: endDate,
  });
  

  function handleReferenceToggle() {
    setShowReference(!showReference);
  }

  useEffect(() => {}, [hoveredAnnotation]);

  /**
   * Call helper function to create an export of the SVG (based on
   * its id) and name it according to the active groups.
   */
  function handleExport(containerId, scores) {
    const groupIdsString = toLower(keys(scores).join('-'));
    saveSvgToImage(containerId, `keencorp-line-chart-${groupIdsString}.png`);
  }

  /**
   * Makes sure you cannot zoom the chart infinately.
   * Once the distance between the top and bottom points is smaller than MIN_ZOOM_DOMAIN disallow further zooming
   */
  function sanitizeBrushLocation(location) {
    if (!location) {
      return location;
    }

    const { top, bottom } = location;
    const delta = top - bottom;

    /** If the yDomain is smaller than the MIN_SIZE extend it on both sides */
    if (delta < MIN_ZOOM_DOMAIN) {
      return {
        top: Math.floor(top + (MIN_ZOOM_DOMAIN - delta) / 2),
        bottom: Math.max(Math.floor(bottom - (MIN_ZOOM_DOMAIN - delta) / 2), 0),
      };
    }

    /** Otherwise return the floored domain values */
    return {
      top: Math.floor(top),
      bottom: Math.max(Math.floor(bottom), 0),
    };
  }

  /**
   * Sets a new brushLocation when a user drags the brush.
   */
  function handleBrushDrag(area) {
    setBrushLocation(
      sanitizeBrushLocation({
        bottom: brushLocation.bottom + (area.top - area.bottom),
        top: brushLocation.top + (area.top - area.bottom),
      })
    );
  }

  /**
   * Sets a new brushLocation when a user ends dragging
   */
  function handleBrushEnd(area) {
    setBrushLocation(sanitizeBrushLocation(area));
  }

  /**
   * Sets the selected annotation when a user clicks an annotation
   */
  function handleSelectAnnotation(id) {
    if (selectedAnnotation === id) {
      onChangeSelectedAnnotation(undefined);
    } else {
      onChangeSelectedAnnotation(id);
    }
  }

  /**
   * Removes the selected annotation from the URL when the user closes the modal
   */
  function handleCloseAnnotation() {
    onChangeSelectedAnnotation(undefined);
  }

  /**
   * Check whether or not the data is valid in order to render the chart
   * or show a placeholder wit no data message instead.
   * Loop over the groups that should be active and check if they contain
   * data. If not filter them out of the list.
   */
  const groupIds = keys(scores);
  const groupsWithData = groupIds.filter(id => !isEmpty(scores[id]));
  const hasData = groupsWithData.length >= 1;
  const editType = "manual";

  /** If the user disables the "ALL" group, we shouldn't show the annotations for the ALL line. */
  let annotationGroups = { ...requestGroups };
  if (!showReference) {
    delete annotationGroups[REFERENCE_ID];
  }

  return (
    <Section>
      <ChartHeader>
        <Title>KeenCorp index over time</Title>
        <ButtonGroup
          editModeEnabled={editModeEnabled}
          onEditModeClick={onEditModeClick}
          onExportClick={handleExport}
          onReferenceToggle={handleReferenceToggle}
          showReference={showReference}
          onLineConfigurationToggle={onLineConfigurationToggle}
          scores={scores}
          lineConfiguration={lineConfiguration}
        />
      </ChartHeader>
      {annotationIds && (
        <DataFetcher
          url={createApiUrl({
            path: 'request_annotations_range',
            request: {
              groups: annotationGroups,
              time_selection: {
                from: startDate,
                to: endDate,
              },
            },
            count: dataFetcherCounter,
          })}
          render={annotationsData => {
            const annotationList = delve(annotationsData, 'data.annotations');

            let activeAnnotation;
            if (annotationList) {
              activeAnnotation = annotationList.find(
                item => item._id === hoveredAnnotation
              );
            }

            return (
              <>
                {brushLocation && (
                  <ResetZoomButton onClick={() => setBrushLocation(null)}>
                    Reset zoom
                  </ResetZoomButton>
                )}
                <div id="scores-chart">
                  <LineChartHeader>
                    <LineChartLegend
                      showReference={showReference}
                      groupsWithData={groupsWithData}
                      groupIds={groupIds}
                    />
                  </LineChartHeader>
                  {!hasData && (
                    <Placeholder>
                      <Content>
                        <p>
                          Unfortunately we could not retrieve data for this
                          period.
                        </p>
                      </Content>
                      <PlaceholderSVG />
                    </Placeholder>
                  )}
                  {hasData && (
                    <LineChart
                      startDate={startDate}
                      endDate={endDate}
                      scores={scores}
                      showReference={showReference}
                      groupsWithData={groupsWithData}
                      lineConfiguration={lineConfiguration}
                      brushLocation={brushLocation}
                      onBrushDrag={handleBrushDrag}
                      onBrushEnd={handleBrushEnd}
                      annotationIsBeingCreated={annotationIsBeingCreated}
                      dateRangeNewAnnotation={dateRangeNewAnnotation}
                      activeAnnotation={activeAnnotation}
                      hasBrush
                      key={
                        /**
                         * Makes sure the LineChart is mounted again when the modal closes or opens.
                         * Otherwise it does not update when the scrollbar is visible or not due to
                         * overflow:hidden; on the body
                         */
                        selectedAnnotation ? 'line-chart' : 'line-chart-modal'
                      }
                    />
                  )}
                </div>

                {hasData && (
                  <>
                    <Annotations
                      annotationList={annotationList}
                      handleSelectAnnotation={handleSelectAnnotation}
                      hoveredAnnotation={hoveredAnnotation}
                      handleHoverAnnotation={setHoveredAnnotation}
                    />
                    <ViewAllAnnotationsButton
                      onClick={() => {
                        setShowAllAnnotations(true);
                      }}
                    >
                      View all annotations
                    </ViewAllAnnotationsButton>
                  </>
                )}

                {selectedAnnotation && (
                  <AnnotationModal
                    setShowAllAnnotations={setShowAllAnnotations}
                    selectedAnnotation={selectedAnnotation}
                    handleCloseAnnotation={handleCloseAnnotation}
                    handleExport={handleExport}
                    increaseCount={increaseCount}
                    onLineConfigurationToggle={onLineConfigurationToggle}
                    lineConfiguration={lineConfiguration}					
                  />
                )}

                {showAllAnnotations && (
                  <AllAnnotationsModal
                    handleSelectAnnotation={handleSelectAnnotation}
                    increaseCount={increaseCount}
                    annotationIds={annotationIds}
                    dataFetcherCounter={dataFetcherCounter}
                    hasData={hasData}
                    startDate={startDate}
                    endDate={endDate}
                    requestGroups={requestGroups}
                    setShowAllAnnotations={setShowAllAnnotations}
                  />
                )}

                {editModeEnabled && (
                  <CreateAnnotation
                    dateRange={dateRangeNewAnnotation}
                    setDateRange={setDateRangeNewAnnotation}
                    setAnnotationIsBeingCreated={setAnnotationIsBeingCreated}
                    onEditModeClick={onEditModeClick}
                    increaseCount={increaseCount}
                    groupsWithData={groupsWithData}
                    showReference={showReference}
					editType={editType}
					connections={connections}
                  />
                )}
              </>
            );
          }}
        />
      )}
    </Section>
  );
};

LineChartWrapper.propTypes = {
  activeGroupIds: arrayOf(string).isRequired,
  scores: objectOf(array).isRequired,
  editModeEnabled: bool,
  onEditModeClick: func.isRequired,
  lineConfiguration: arrayOf(string).isRequired,
  onLineConfigurationToggle: func.isRequired,
  annotationIds: array,
  clickedNode: object,
  increaseCount: func.isRequired,
  dataFetcherCounter: number.isRequired,
  clearClickedNode: func.isRequired,
  selectedAnnotation: string,
  onChangeSelectedAnnotation: func.isRequired,
  startDate: number.isRequired,
  endDate: number.isRequired,
};

export default withRouter(withGroupContext(LineChartWrapper));

import React from 'react';
import { func, bool, string, objectOf, array, arrayOf } from 'prop-types';
import { saveAs } from 'file-saver';

import { withGroupContext } from 'context/groupContext';
import convertGroupIdToLabel from 'helpers/convertGroupIdToLabel';

import Tooltip from '@material-ui/core/Tooltip';
import ToggleButton from '@material-ui/lab/ToggleButton';

import {
  Label,
  ExportIcon,
  DownloadIcon,
  EditIcon,
  RemoveIcon,
  TooltipButton,
  EditButton,
  RemoveButton,
  StyledSwitch,
  Wrapper,
  ToggleButtonWrapper,
} from './ButtonGroup.style';
import isLegacyBrowser from '../../helpers/isLegacyBrowser';

export function ButtonGroup(props) {
  const {
    editModeEnabled,
    onExportClick,
    onEditModeClick,
    onReferenceToggle,
    lineConfiguration,
    onLineConfigurationToggle,
    showReference,
    referenceId,
    userHasFullAccess,
    scores,
    selectedAnnotation,
    onRemoveAnnotation,
  } = props;

  /**
   * If the annotation modal is open, export the annotation chart. Otherwise the default chart.
   */
  function handleExportClick() {
    const containerId = selectedAnnotation
      ? '#annotation-chart'
      : '#scores-chart';

    onExportClick(containerId, scores);
  }
  
  

  return (
    <Wrapper>
      {!selectedAnnotation && (
        <span>
          <Label>{`"${convertGroupIdToLabel(referenceId)}" ${
            showReference ? 'enabled' : 'disabled'
          }`}</Label>
          <Tooltip
            title={`${showReference ? 'disable' : 'enable'}`}
            placement="top"
          >
            <StyledSwitch
              onChange={onReferenceToggle}
              checked={showReference}
            />
          </Tooltip>
        </span>
      )}
      <Tooltip title="Line smoothing" placement="top">
        <ToggleButtonWrapper values={lineConfiguration}>
          <ToggleButton
            onClick={() => onLineConfigurationToggle('none')}
            value="none"
          >
            None
          </ToggleButton>
          <ToggleButton
            onClick={() => onLineConfigurationToggle('smooth')}
            value="smooth"
          >
            Smooth
          </ToggleButton>
          <ToggleButton
            onClick={() => onLineConfigurationToggle('linear')}
            value="linear"
          >
            Linear
          </ToggleButton>
        </ToggleButtonWrapper>
      </Tooltip>

      {!isLegacyBrowser && (
        <>
          <Tooltip title="Export chart" placement="top">
            <TooltipButton variant="contained" onClick={handleExportClick}>
              <ExportIcon />
            </TooltipButton>
          </Tooltip>
          <Tooltip title="Export data" placement="top">
            <TooltipButton
              variant="contained"
              onClick={() => {
                const data = encodeURIComponent(JSON.stringify(scores));
                const blob = `data:text/json;charset=utf-8,${data}`;
                saveAs(blob, 'scores.json');
              }}
            >
              <DownloadIcon />
            </TooltipButton>
          </Tooltip>
        </>
      )}
      {userHasFullAccess &&
        (selectedAnnotation ? (
          <Tooltip title="Remove annotation" placement="top">
            <RemoveButton
              variant="contained"
              onClick={() => onRemoveAnnotation(selectedAnnotation)}
            >
              <RemoveIcon />
            </RemoveButton>
          </Tooltip>
        ) : (
          <Tooltip title="Create annotation" placement="top">
            <EditButton
              variant="contained"
              onClick={onEditModeClick}
              editModeEnabled={editModeEnabled}
            >
              <EditIcon />
            </EditButton>
          </Tooltip>
        ))}
    </Wrapper>
  );
}

ButtonGroup.propTypes = {
  referenceId: string.isRequired,
  showReference: bool,
  onReferenceToggle: func,
  lineConfiguration: arrayOf(string).isRequired,
  onLineConfigurationToggle: func.isRequired,
  editModeEnabled: bool,
  onExportClick: func.isRequired,
  onEditModeClick: func,
  onRemoveAnnotation: func,
  userHasFullAccess: bool.isRequired,
  scores: objectOf(array).isRequired,
  selectedAnnotation: string,
};

export default withGroupContext(ButtonGroup);

import createApiUrl from 'helpers/createApiUrl';

export default async function createAnnotation(annotation) {
  // The request object with an annotation should move to the 'body' of a POST call.
  const url = createApiUrl({ path: 'create_annotation', request: annotation });
  const result = {
    success: false,
    error: false,
    payload: null,
  };

  try {
    /**
     * We are creating a GET request here, which should eventually be a POST
     * request with a body instead. Instead of adding all the data of the
     * annotation to the url this should be moved to the 'body'.
     */
    const response = await fetch(url, { method: 'GET' });

    if (response.ok) {
      const payload = await response.json();

      if (payload.success) {
        return {
          ...result,
          success: true,
          error: false,
          payload,
        };
      }

      return {
        ...result,
        success: false,
        error: true,
        payload: payload.description,
      };
    }

    return {
      ...result,
      success: false,
      error: true,
      payload: response,
    };
  } catch (error) {
    console.error({ error });
    return {
      ...result,
      success: false,
      error: true,
      payload: error,
    };
  }
}

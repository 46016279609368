import React from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { transparentize } from 'polished';

import logoutUser from 'helpers/logoutUser';
import ButtonBase from '@material-ui/core/ButtonBase';

import { ReactComponent as LogOutSVG } from 'icons/logout.svg';

const Background = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 20em;
  padding: 0 2em;
  background: white;
`;

const Button = styled(ButtonBase)`
  && {
    justify-content: flex-start;
    width: 100%;
    padding: 1rem 1.5rem 1em 0;
    font-size: 0.875em;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: ${({ theme }) => theme.base.PastelBlue};
    border-top: 1px solid ${({ theme }) => theme.base.Mystic};
    transition: color 150ms ease-out;

    svg {
      width: 1.3125rem;
      height: 1.1875rem;
      display: inline-block;
      margin-right: 1em;
      transform: rotate(180deg);

      path {
        stroke: ${({ theme }) => theme.base.PastelBlue};
        transition: stroke 150ms ease-out;
      }
    }

    &:hover,
    &:focus {
      color: ${({ theme }) => transparentize(0.5, theme.base.PastelBlue)};

      svg path {
        stroke: ${({ theme }) => transparentize(0.5, theme.base.PastelBlue)};
      }
    }
  }
`;

function LogOutButton({ history }) {
  const logUserOut = () => {
    logoutUser()
      .then(response => history.push('/login'))
      .catch(error => console.error(error));
  };
  return (
    <Background>
      <Button onClick={logUserOut} disableRipple>
        <LogOutSVG /> Log out
      </Button>
    </Background>
  );
}

export default withRouter(LogOutButton);

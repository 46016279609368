import React, { Component } from 'react';
import { object, func, number, bool, oneOf } from 'prop-types';
import { DateRangePicker } from 'react-dates';
import { first } from 'lodash';
import delve from 'dlv';
import 'react-dates/initialize';

import './datepicker.css';

import {
  StyledDateRangePickerWrapper,
  SubtitleBar,
  Subtitle,
} from './DateControl.style';
import DatePresets from './DatePresets/DatePresets';
import createApiUrl from '../../helpers/createApiUrl';
import DataFetcher from '../DataFetcher/DataFetcher';
import { today, getPresets } from './presets';
import { REFERENCE_ID } from '../../config';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

class DateControl extends Component {
  state = {
    focusedInput: null,
    startDate: this.props.startDate,
    endDate: this.props.endDate,
  };

  /**
   * Check if the component is updated based on the props, if so update the state as well.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.startDate !== prevProps.startDate) {
      this.setState({ startDate: this.props.startDate });
    }
    if (this.props.endDate !== prevProps.endDate) {
      this.setState({ endDate: this.props.endDate });
    }
  }

  /**
   * Handle changes in dates so we can use it to submit after the date picker
   * is closed.
   */

  onDatesChange = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      this.submitDates({ startDate, endDate });
    } else {
      this.setState({ startDate, endDate });
    }
  };

  /**
   * Change focus of the date picker based on the date selection, either start or
   * end date.
   */
  onFocusChange = focusedInput => {
    this.setState({ focusedInput });
  };

  /**
   * Submit dates via a prop function, this will eventually save the new dates to the URL.
   */
  submitDates = ({ startDate, endDate }) => {
    /** Only submit dates if the start and end date are set */
    if (startDate && endDate) {
      /**
       * First update the component state so we actual save a date. In cases when a date is
       * removed from the date picker, we want to reset this date to a default.
       */
      this.setState(
        { startDate, endDate },
        this.props.submitDates({
          startDateMoment: startDate,
          endDateMoment: endDate,
        })
      );
    }
  };

  render() {
    const { startDate, endDate, focusedInput } = this.state;

    const {
      minimumNights,
      hasDatePresets,
      hasHeader,
      showDefaultInputIcon,
      type,
    } = this.props;

    /**
     * Determine the initial visible month (the date to focus the date picker on).
     * This could either be the end/start date from the state or the initial state
     * from props.
     */
    const initialVisibleMonth = () => {
      const newEndDate = endDate || this.props.endDate;
      const newStartDate = startDate || this.props.startDate;
      return focusedInput === 'endDate' ? newEndDate : newStartDate;
    };

    return (
      <DataFetcher
        url={createApiUrl({
          path: 'request_scores',
          request: {
            from: 0,
            to: today.unix(),
            groups: {
              [REFERENCE_ID]: [REFERENCE_ID],
            },
          },
        })}
        render={({ data, loading, error }) => {
          if (loading || !data) {
            return null;
          }

          /** Get the first date in the 'All' data which is used in the 'Everything' date preset */
          const firstDataObject = first(
            delve(data, ['scores', REFERENCE_ID], [])
          );
          const firstDate = delve(firstDataObject, 'date', 0);
          const presets = getPresets(firstDate);

          return (
            <ErrorBoundary error={error}>
              <StyledDateRangePickerWrapper type={type}>
                {hasHeader && (
                  <SubtitleBar>
                    <Subtitle>Start date</Subtitle>
                    <Subtitle>End date</Subtitle>
                  </SubtitleBar>
                )}
                <DateRangePicker
                  renderCalendarInfo={() => {
                    if (hasDatePresets) {
                      return (
                        <DatePresets
                          startDate={startDate}
                          endDate={endDate}
                          presets={presets}
                          onDatesChange={this.onDatesChange}
                        />
                      );
                    }
                    return null;
                  }}
                  displayFormat="YYYY-MM-DD"
                  firstDayOfWeek={1}
                  transitionDuration={0}
                  minimumNights={minimumNights}
                  noBorder
                  showDefaultInputIcon={showDefaultInputIcon}
                  hideKeyboardShortcutsPanel
                  startDatePlaceholderText="Start date"
                  startDateId="startDate"
                  startDate={startDate}
                  endDatePlaceholderText="End date"
                  endDateId="endDate"
                  endDate={endDate}
                  onDatesChange={this.onDatesChange}
                  focusedInput={focusedInput}
                  onFocusChange={this.onFocusChange}
                  isOutsideRange={() => false}
                  initialVisibleMonth={() => initialVisibleMonth()}
                  onClose={this.submitDates}
                  horizontalMargin={30}
                />
              </StyledDateRangePickerWrapper>
            </ErrorBoundary>
          );
        }}
      />
    );
  }
}

DateControl.defaultProps = {
  minimumNights: 6,
  type: 'full',
};

DateControl.propTypes = {
  startDate: object.isRequired,
  endDate: object.isRequired,
  submitDates: func.isRequired,
  minimumNights: number,
  hasDatePresets: bool,
  showDefaultInputIcon: bool,
  type: oneOf(['inline', 'full']),
};

export default DateControl;

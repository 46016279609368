import React from 'react';
import Circle from '../Circle/Circle';
import getColorById from '../../../helpers/getColorById';

const CircleNodes = React.memo(props => {
  const {
    size,
    margin,
    handleMouseLeave,
    nodes,
    handleHover,
    colorById,
  } = props;

  return (
    <svg
      width={size.width + margin.left + margin.right}
      height={size.height + margin.top + margin.bottom}
    >
      <g
        transform={`translate(${margin.left},${margin.top})`}
        onMouseLeave={handleMouseLeave}
      >
        {nodes.map((node, index) => (
          <Circle
            key={node.data.id}
            node={node}
            index={index}
            color={getColorById(colorById, node.data.id)}
            handleHover={handleHover}
          />
        ))}
      </g>
    </svg>
  );
});

export default CircleNodes;

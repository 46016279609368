import { values, flatten } from 'lodash';

/**
 * Flattens a object with arrays of scores data and retrieves all the annotation
 * ids and maps them to a single list.
 *
 * @param {object} scores Object with scores data retrieved from the API
 */
export default function retrieveAnnotationIdsFromScores(scores) {
  return flatten(
    flatten(values(scores))
      .filter(({ annotations }) => annotations && annotations.length > 0) // Filter points that do not have annotaions
      .map(
        point =>
          point.annotations
            .filter(({ id }) => id) // Filter on annotations that contain an id
            .map(annotation => annotation.id) // Map all annotations so we can make a list
      )
  );
}

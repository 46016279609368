import Cookies from 'js-cookie';

/**
 * This checks if a user profile related cookie exists, if true,
 * the user is logged in.
 */
export default function getUserProfile() {
  const sessionId = Cookies.get('session_id');
  const userHasFullAccess = Cookies.get('full_access');

  return {
    sessionId,
    userHasFullAccess,
  };
}

import { pick } from 'lodash';
import colors from '../styles/theme/colors';

/**
 * Extends the current colorsById object with new colors for groups that aren't assigned yet
 *
 * @param {Object} currentColorsById the current colorsById object
 * @param {Array} groups the active groups that possible need colors assigned
 *
 * @returns {Object} the extended colorsById object
 */
function assignNewColorsToGroups(currentColorsById, groups) {
  let newColorById = { ...currentColorsById };

  if (groups) {
    const newActiveGroupIds = Object.keys(groups);

    /** Remove items that are not part of the new activeGroups **/
    newColorById = pick(newColorById, newActiveGroupIds);

    const usedColors = Object.values(newColorById);
    const availableColors = colors.dataOrdered.filter(
      color => !usedColors.includes(color)
    );

    /** Asign available colors to the new ids that didn't have a color assigned yet */
    newActiveGroupIds
      .filter(key => !newColorById.hasOwnProperty(key))
      .forEach((key, index) => {
        newColorById[key] = availableColors[index];
      });
  }

  return newColorById;
}

export default assignNewColorsToGroups;

import { css } from 'styled-components/macro';
import { rgba } from 'polished';
import colors from 'styles/theme/colors';

export const defaultButtonStyle = css`
  background: white;
  border: 1px solid ${({ theme }) => theme.base.Mystic};
  box-shadow: 0 2px 15px ${({ theme }) => rgba(theme.base.DarkBlue, 0.04)};
  transition: all 150ms ease-out;

  &:hover,
  &:active {
    background: ${({ theme }) => theme.base.Blue};

    svg {
      stroke: white;
    }
  }
  &:active {
    box-shadow: 0 2px 15px ${({ theme }) => rgba(theme.base.DarkBlue, 0.3)};
  }
`;

export const iconButtonStyle = css`
  ${defaultButtonStyle};
  min-width: 3em;
  min-height: 3em;
  padding: 0;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MUButtonStyle = css`
  background: white;
  border: 1px solid ${({ theme }) => theme.base.Mystic};
  box-shadow: 0 2px 15px ${({ theme }) => rgba(theme.base.DarkBlue, 0.04)};
  transition: all 150ms ease-out;

  &:hover,
  &:active {
    background: ${({ theme }) => theme.base.Blue};

    svg {
      fill: white;
    }
  }
  &:active {
    box-shadow: 0 2px 15px ${({ theme }) => rgba(theme.base.DarkBlue, 0.3)};
  }
`;

export const MUIconButtonStyle = css`
  ${MUButtonStyle};
  min-width: 3em;
  min-height: 3em;
  padding: 0;
`;

export const labelStyle = css`
  font-size: 0.625em;
  text-transform: uppercase;
  letter-spacing: 0.06em;
`;

export const discreteButtonStyle = css`
  && {
    ${defaultButtonStyle};
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.625em;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    color: ${colors.base.PastelBlue};

    &:hover,
    &:active {
      color: white;
    }
  }
`;

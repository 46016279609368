import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { RouterToUrlQuery } from 'react-url-query';
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro';
import { customMuiTheme } from 'styles/theme/variables';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import AppRoutes from 'AppRoutes';

import base from 'styles/global/base';
import fonts from 'styles/global/fonts';
import typography from 'styles/global/typography';
import colors from 'styles/theme/colors';
import variables from 'styles/theme/variables';

const GlobalStyle = createGlobalStyle`
  ${base}
  ${fonts}
  ${typography}
`;

class App extends Component {
  render() {
    return (
      <>
        <GlobalStyle />
        <Router>
          <RouterToUrlQuery>
            <MuiThemeProvider theme={createMuiTheme(customMuiTheme)}>
              <ThemeProvider theme={{ ...colors, ...variables }}>
                <Route component={AppRoutes} />
              </ThemeProvider>
            </MuiThemeProvider>
          </RouterToUrlQuery>
        </Router>
      </>
    );
  }
}

export default App;

import React, { Component } from 'react';
import { object, func } from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import convertGroupsToMenuItems from './convertGroupsToMenuItems';

import LogOutButton from 'components/LogOutButton/LogOutButton';
import NestedList from 'components/NestedList/NestedList';
import { ReactComponent as Logo } from './keencorp-logo.svg';

import Button from '@material-ui/core/Button';
import { discreteButtonStyle } from 'styles/base-classes';

const Container = styled('div')`
  flex: 0 0 20rem;
  max-width: 20rem;
  padding: 2em 2em 6em;
  background-color: white;
`;

const StyledLogo = styled(Logo)`
  width: 80%;
  padding: 1em;
  margin-bottom: 1em;
`;

const HomeLink = styled(Link)`
  display: block;
  text-align: center;
`;

const ClearButton = styled(Button)`
  ${discreteButtonStyle};
  width: 100%;

  && {
    margin-bottom: 2em;
  }
`;

class Sidebar extends Component {
  render() {
    const { menuData, onChangeActiveGroups, activeGroupIds } = this.props;
    if (!menuData) return null;

    const items = convertGroupsToMenuItems(menuData);
    const isClearButtonEnabled = activeGroupIds.length > 1;

    return (
      <Container>
        <HomeLink to="/">
          <StyledLogo />
        </HomeLink>
        {items && (
          <>
            <ClearButton
              disabled={!isClearButtonEnabled}
              variant="contained"
              onClick={() => {
                onChangeActiveGroups([]);
              }}
            >
              Clear selection
            </ClearButton>
            <NestedList
              menuItems={items}
              onChangeActiveGroups={onChangeActiveGroups}
            />
          </>
        )}
        <LogOutButton />
      </Container>
    );
  }
}

NestedList.propTypes = {
  onChangeActiveGroups: func.isRequired,
  menuData: object,
};

export default Sidebar;

import React, { Component, Fragment } from 'react';
import { number, string, oneOfType, instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { toString } from 'lodash';

import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  text-align: center;
  color: white;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.base.Blue},
    ${({ theme }) => theme.base.DarkBlue}
  );
`;

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 0;
`;

const Paragraph = styled.p`
  margin-top: 2em;
  font-size: 1em;
`;

const BackToHomeButton = styled(Button)`
  && {
    margin: 1.5em 0.5em;
    font-size: 0.75em;
    font-weight: 400;
    color: white;
    border-color: white;
    border-radius: 2px;
    text-transform: none;
    transition: all 150ms ease-out;

    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.base.Blue};
      border-color: ${({ theme }) => theme.base.Blue};
    }
  }
`;

/**
 * This renders an error page. Based on the status code (if
 * provided) the page shows a different error message.
 */
class ErrorPage extends Component {
  render() {
    const { status, error } = this.props;

    const renderErrorMessage = () => {
      if (status === 404) {
        return (
          <Fragment>
            <Title>Page not found!</Title>
            <p>This resource was either deleted or it has never existed.</p>
            <BackToHomeButton component={Link} to="/" variant="outlined">
              Take me back home
            </BackToHomeButton>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <Title>Something went wrong!</Title>
          <p>
            An error occurred, please try to reload the page or go back to home.
          </p>
          <div>
            <BackToHomeButton
              variant="outlined"
              onClick={() => window.location.reload(true)}
            >
              Reload
            </BackToHomeButton>
            <BackToHomeButton component={Link} to="/" variant="outlined">
              Go to homepage
            </BackToHomeButton>
          </div>
          {error && <Paragraph>{toString(error)}</Paragraph>}
        </Fragment>
      );
    };

    return <Container>{renderErrorMessage()}</Container>;
  }
}

ErrorPage.propTypes = {
  status: number,
  error: oneOfType([instanceOf(Error), string]),
};

export default withRouter(ErrorPage);

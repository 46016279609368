import styled, { css } from 'styled-components/macro';
import {
  MUIconButtonStyle,
  iconButtonStyle,
  labelStyle,
} from 'styles/base-classes';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { rgba } from 'polished';

import { ReactComponent as ExportSVG } from 'icons/export.svg';
import { ReactComponent as DownloadSVG } from 'icons/download-json.svg';
import { ReactComponent as EditSVG } from 'icons/edit.svg';
import { ReactComponent as DeleteSVG } from 'icons/delete.svg';

export const Label = styled.label`
  ${labelStyle};
  align-self: center;
`;

export const iconStyle = css`
  transition: all 150ms ease-out;
  stroke: ${({ theme }) => theme.base.PastelBlue};
`;

export const ExportIcon = styled(ExportSVG)`
  ${iconStyle};
`;

export const DownloadIcon = styled(DownloadSVG)`
  ${iconStyle};
`;

export const EditIcon = styled(EditSVG)`
  ${iconStyle};
`;

export const RemoveIcon = styled(DeleteSVG)`
  transition: all 150ms ease-out;
`;

export const TooltipButton = styled(Button)`
  && {
    ${iconButtonStyle};

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    + button,
    + a {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

const activeStyle = css`
  background: ${({ editModeEnabled, theme }) =>
    editModeEnabled ? theme.base.Blue : 'white'};

  svg {
    stroke: ${({ editModeEnabled, theme }) =>
      editModeEnabled ? 'white' : theme.base.PastelBlue};
  }
`;

export const EditButton = styled(TooltipButton)`
  && {
    ${({ editModeEnabled }) => editModeEnabled && activeStyle};
  }
`;

export const RemoveButton = styled(TooltipButton)`
  && {
    ${MUIconButtonStyle};

    svg {
      stroke: none !important;
    }

    &:hover,
    &:active {
      path {
        fill: white;
      }
    }
  }
`;

export const StyledSwitch = styled(Switch)`
  && {
    position: relative;
    z-index: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  height: 2.625em;
`;

export const ToggleButtonWrapper = styled.div`
  && {
    display: flex;
    border: 1px solid ${({ theme }) => theme.base.Mystic};
    box-shadow: 0 2px 15px ${({ theme }) => rgba(theme.base.DarkBlue, 0.04)};
    transition: all 150ms ease-out;
    border-radius: 3px;

    &:not(:last-child) {
      margin-right: 1em;
    }

    ${({ values }) => css`
        button[value="${values[0]}"],
        button[value="${values[1]}"],
        button[value="${values[2]}"]
        {
          background: ${({ theme }) => theme.base.Blue};
          color:white;
       }
    `};
  }

  button {
    height: auto;
    ${labelStyle};
    color: ${({ theme }) => theme.base.PastelBlue};
  }
`;

import moment from 'moment';

import convertToLOESS from '../../../helpers/convertToLOESS';
import convertToLeastSquares from '../../../helpers/convertToLeastSquares';

// Map the data to a format usable by react-vis
const mapData = ({ data, id, xKey, yKey, lineConfiguration, xDomain }) => {
  // Checks if the data point is in the xDomain range
  function isVisibleDate(item) {
    const date = moment.unix(item[xKey]).valueOf();

    return date >= xDomain[0] && date <= xDomain[1];
  }

  if (!data) {
    return [];
  }

  let visibleData = [...data];

  // If lineConfiguration is not set to 'none' convert the data with the alogritms provided by KeenCorp
  if (lineConfiguration === 'smooth') {
    visibleData = convertToLOESS(data, 0.1);
  } else if (lineConfiguration === 'linear') {
    visibleData = convertToLeastSquares(data);
  }

  return (
    visibleData
      // If the data points fall outside of the xDomain they should be removed.
      .filter(isVisibleDate)
      .map(item => {
        const date = moment.unix(item[xKey]).valueOf();

        return {
          ...item,
          id,
          x: date,
          y: item[yKey],
        };
      })
  );
};

export default mapData;

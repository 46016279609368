import { css } from 'styled-components/macro';
import colors from 'styles/theme/colors';

const base = css`
  html {
    box-sizing: border-box;
    height: 100%;
  }

  /** Add overflow hidden to both the HTML and BODY element otherwise you will still be able to scroll on iOS */
  .annotation-modal-open {
    &,
    body {
      overflow: hidden;
      position: relative;
      height: 100%;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  *::selection {
    color: white;
    background-color: ${colors.base.Blue};
  }

  body {
    height: 100%;
    margin: 0;
    background-color: ${colors.base.Smoke};
    color: ${colors.base.PastelBlue};
  }

  /**
 * Add the correct display in browsers that don't support HTML5 elements by default.
 */

  article,
  aside,
  footer,
  header,
  nav,
  figcaption,
  figure,
  main,
  section {
    display: block;
  }

  ul,
  dl {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  dt,
  dd {
    margin: 0;
    padding: 0;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  a:focus,
  button:focus,
  summary:focus,
  input[type='radio']:focus + label {
    outline: none;
    box-shadow: none;
  }

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background: none;
    border: 0;
  }

  /** IE11 by default shifts button children when focussing, See: https://stackoverflow.com/questions/10305658/prevent-button-from-shifting-during-click-in-ie  */
  button > * {
    position: relative;
  }
`;

export default base;

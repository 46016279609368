import React from 'react';

export const GroupContext = React.createContext({
  activeGroups: {},
  activeGroupIds: [],
  startDate: null,
  endDate: null,
  referenceId: undefined,
  colorById: undefined,
  userHasFullAccess: false,
});

export const withGroupContext = Component => props => (
  <GroupContext.Consumer>
    {contextProps => <Component {...contextProps} {...props} />}
  </GroupContext.Consumer>
);

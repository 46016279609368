import React from 'react';
import { number, string } from 'prop-types';
import styled from 'styled-components/macro';
import moment from 'moment';
import { keys } from 'lodash';
import convertGroupIdToLabel from 'helpers/convertGroupIdToLabel';

const Container = styled.div`
  position: relative;
  transform: translate(0, -0.5em);
  pointer-events: none;
  background: ${({ theme }) => theme.base.PastelBlue};
  padding: 0.5em;
  color: white;
  border-radius: 3px;
  width: 12em;
  margin-left: -6em;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 7px 0 7px;
    border-color: ${({ theme }) => theme.base.PastelBlue} transparent
      transparent transparent;
    position: absolute;
    left: 50%;
    top: 100%;
    margin-top: -1px;
    margin-left: -7px;
  }
`;

const Time = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.75em;
`;

const Groups = styled.p`
  font-size: 0.75em;
  margin-top: 0;
  margin-bottom: 0.5em;
`;

/** Shows an annotation tooltip once you hover over it */
const AnnotationTooltip = props => {
  const { title, from, to, groups } = props;

  return (
    <Container>
      <Time>
        {moment.unix(from).format('YYYY-MM-DD')} -{' '}
        {moment.unix(to).format('YYYY-MM-DD')}
      </Time>
      <Groups>
        {keys(groups)
          .map(id => convertGroupIdToLabel(id))
          .join(', ')}
      </Groups>
      {title}
    </Container>
  );
};

AnnotationTooltip.propTypes = {
  /** The title displayed */
  title: string.isRequired,
  /** from property coming from annotation.time_selection */
  from: number.isRequired,
  /** to property coming from annotation.time_selection */
  to: number.isRequired,
};

export default AnnotationTooltip;

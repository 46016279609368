import React, { Component, Fragment } from 'react';
import { bool, arrayOf, func, string, object } from 'prop-types';
import { includes } from 'lodash';
import styled from 'styled-components/macro';
import { ellipsis } from 'polished';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const StyledCheckbox = styled(Checkbox)`
  && {
    width: 1em;
    height: 1em;
    margin-left: 0.5em;
  }
`;

const SyledList = styled(List)`
  && {
    padding: 0 0 0 1em;
  }
`;

const StyledListItemText = styled(ListItemText)`
  && {
    span {
      ${ellipsis('100%')};
      vertical-align: bottom;
    }
  }
`;

const StyledListItem = styled(ListItem)`
  && {
    cursor: default;
    transition: color 150ms ease-in-out;

    ${StyledListItemText} span {
      transition: color 150ms ease-in-out;
    }

    &:hover {
      color: ${({ theme }) => theme.base.Blue};

      ${StyledListItemText} span {
        color: ${({ theme }) => theme.base.Blue};
      }
    }
  }
`;

class NestedListItem extends Component {
  state = {
    isOpen: false,
  };

  toggleList = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleClick = (id, requestParam, isActive, isDisabled) => {
    if (!isDisabled) {
      if (isActive) {
        this.props.deactivateGroup(id, requestParam);
      } else {
        this.props.activateGroup(id, requestParam);
      }
    }
  };

  render() {
    const {
      id,
      displayName,
      requestParam,
      activeGroupIds,
      showCheckbox,
      children,
    } = this.props;
    const isActive = includes(activeGroupIds, id);
    const isDisabled = !isActive && activeGroupIds.length > 7;

    if (children.length >= 1) {
      return (
        <Fragment>
          <StyledListItem button={false} disableRipple disableGutters>
            {showCheckbox && (
              <StyledCheckbox
                disabled={isDisabled}
                onChange={() =>
                  this.handleClick(id, requestParam, isActive, isDisabled)
                }
                checked={isActive}
                color="primary"
              />
            )}
            <StyledListItemText
              primary={displayName}
              onClick={this.toggleList}
              title={displayName}
            />
            {this.state.isOpen ? (
              <ExpandLess onClick={this.toggleList} />
            ) : (
              <ExpandMore onClick={this.toggleList} />
            )}
          </StyledListItem>
          {this.state.isOpen && (
            <SyledList disablePadding>{children}</SyledList>
          )}
        </Fragment>
      );
    }

    return (
      <StyledListItem
        button={false}
        disabled={isDisabled}
        disableRipple
        disableGutters
      >
        {showCheckbox && (
          <StyledCheckbox
            disabled={isDisabled}
            onChange={() =>
              this.handleClick(id, requestParam, isActive, isDisabled)
            }
            checked={isActive}
            color="primary"
          />
        )}
        <StyledListItemText
          primary={displayName}
          title={displayName}
          onClick={() =>
            this.handleClick(id, requestParam, isActive, isDisabled)
          }
        />
      </StyledListItem>
    );
  }
}

NestedListItem.defaultProps = {
  showCheckbox: true,
};

NestedListItem.propTypes = {
  id: string.isRequired,
  displayName: string.isRequired,
  requestParam: object,
  showCheckbox: bool,
  activeGroupIds: arrayOf(string).isRequired,
  activateGroup: func.isRequired,
  deactivateGroup: func.isRequired,
};

export default NestedListItem;

import colors from './colors';

const variables = {
  defaultFontFamily: '"IBM Plex Sans", Helvetica, Arial, sans-serif',
  numbersFontFamily:
    '"IBM Plex Mono", "IBM Plex Sans", Helvetica, Arial, sans-serif',
  defaultFontWeight: '400',
};

export const customMuiTheme = {
  typography: {
    useNextVariants: true,
    fontFamily: variables.defaultFontFamily,
    htmlFontSize: 16,
  },
  palette: {
    primary: {
      main: colors.base.PastelBlue,
    },
    secondary: {
      main: colors.base.Blue,
    },
    text: {
      primary: colors.base.PastelBlue,
      secondary: colors.base.PastelBlue,
      disabled: colors.base.Casper,
      hint: colors.base.PastelBlue,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: colors.base.PastelBlue,
        fontSize: '0.5625em',
        textTransform: 'uppercase',
        letterSpacing: '0.06em',
      },
    },
  },
};

export default variables;
